import {Injectable} from '@angular/core';
import {Observable, Observer, throwError} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {saveAs} from 'file-saver';
import {catchError, tap} from 'rxjs/operators';
import {removeExtension} from '../functions/media.functions';

@Injectable({
    providedIn: 'root'
})
export class NpDownloadService {
    private _exceptionExtensions = ['step'];

    constructor(private _http: HttpClient) {
    }

    public static getBase64Image(img: HTMLImageElement) {
        // We create a HTML canvas object that will create a 2d image
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        // This will draw image
        ctx.drawImage(img, 0, 0);
        // Convert the drawn image to Data URL
        const dataURL = canvas.toDataURL('image/png');
        return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
    }

    public getBase64ImageFromURL(url: string) {
        return new Observable((observer: Observer<string>) => {
            // create an image object
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.src = url;
            if (!img.complete) {
                // This will call another method that will create image from url
                img.onload = () => {
                    observer.next(NpDownloadService.getBase64Image(img));
                    observer.complete();
                };
                img.onerror = (err) => {
                    observer.error(err);
                };
            } else {
                observer.next(NpDownloadService.getBase64Image(img));
                observer.complete();
            }
        });
    }

    public getDownload(b64Data, contentType = '', sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, {type: contentType});
    }


    downloadFile(fileUrl: string, fileName: string, fileExtension: string) {
        return this._http.get(fileUrl, {responseType: 'blob'})
            .pipe(
                tap(response => {
                    // Check if the extension is in the exception list
                    if (this._exceptionExtensions.includes(fileExtension.toLowerCase())) {
                        // Define a MIME type based on the exception extension, e.g., application/STEP
                        const mimeType = `application/${fileExtension}`;
                        // Ensure the file name has the correct extension
                        response = new Blob([response], {type: mimeType});
                    } else {
                        fileName = removeExtension(fileName);
                    }

                    saveAs(response, fileName);
                }),
                catchError(error => throwError(error))
            );
    }
}
