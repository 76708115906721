import {createReducer, on} from '@ngrx/store';
import {FileActions} from './file.actions';

export const fileFeatureKey = 'file';

export interface FileState {
    allowedThumbExtensions: string[];
    allowedThumbSizes: string[];
    errorMessage: string | null;
}

export const initialStateFile: FileState = {
    allowedThumbExtensions: [],
    allowedThumbSizes: [],
    errorMessage: null,
};

export const FileReducer = createReducer(
    initialStateFile,
    on
    (FileActions.fetchThumbParametersSuccess, (state: FileState, {thumbsParameters}) => ({
        ...state,
        allowedThumbExtensions: thumbsParameters.allowedThumbExtensions,
        allowedThumbSizes: thumbsParameters.allowedThumbSizes,
        errorMessage: null,
    })),
    on
    (FileActions.fetchThumbParametersFailure, (state: FileState, {message}) => ({
        ...state,
        allowedThumbExtensions: [],
        errorMessage: message
    }))
);
