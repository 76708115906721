import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import {CaracConfig, CustomFieldDto, NpUser, NpUserInfoService, NPElementType} from '@nextpage/np-sdk-data';
import {SpDicoCarac} from '@data/constants';
import {environment} from '@env';
import { Store } from '@ngrx/store';
import { selectAllDicoCaracConfigs } from '@store/characteristics';
import { concatLatestFrom } from '@ngrx/effects';
import * as _ from 'lodash';
import { selectCaracsExtIds } from '@store/selector';

@Injectable({
    providedIn: 'root',
})
export class SpUserInfoService {
    private _currentUser: NpUser;
    private _currentUser$ = new BehaviorSubject<NpUser>(null);

    constructor(
        private _userInfoService: NpUserInfoService,
        private _store: Store,) {
    }

    public getCurrentUser(): Observable<NpUser> {
        return this._userInfoService.getCurrentUser().pipe(
            map(users => {
                this._currentUser = users && users.length > 0 ? users[0] : null;
                this._currentUser$.next(this._currentUser);
                return this._currentUser;
            })
        );
    }

    public getUserCustomFieldByExtId(fieldExtId: string): Observable<CustomFieldDto> {
        return this._currentUser$.asObservable().pipe(
            filter(response => response !== null && response !== undefined),
            map(user => {
                return user && user.CustomFields ? user.CustomFields.find(x => x.ExtID === fieldExtId) : null;
            })
        );
    }

    public getImportProfileExtId(): Observable<string> {
        return this.getUserCustomFieldByExtId(SpDicoCarac.CP_PROFIL_IMPORT_AVANCE)
            .pipe(
                switchMap(fieldContent => {
                    return fieldContent &&
                    fieldContent.Values &&
                    fieldContent.Values.length > 0 ?
                        of(fieldContent.Values[0].Value) : null;
                })
            );
    }

    /**
     * Retourne l'extId cu canal qui sera utilisé dans la recherche avancée
     * pour récupérer les Produits
     */
    public getChannelExtId(): Observable<CustomFieldDto> {
        return this.getUserCustomFieldByExtId(SpDicoCarac.CP_EXTID_CHANNEL);
    }

    public getMediasExtId(): Observable<CustomFieldDto> {
        return this.getUserCustomFieldByExtId(SpDicoCarac.CP_ID_MEDIAS);
    }

    public getFamilyExtId(): Observable<CustomFieldDto> {
        return this.getUserCustomFieldByExtId(SpDicoCarac.CP_EXT_ID_PRODUIT);
    }

    public getParentExtId(): Observable<CustomFieldDto> {
        return this.getChannelExtId().pipe(
            switchMap(result => {
                environment.useChannelSearch = result?.Values?.length > 0;
                return result?.Values?.length > 0 ? of(result) : this.getFamilyExtId();
            })
        );
    }

    public getUserCustomFieldsByExtIDs(fieldExtIDs: string[]): Observable<CustomFieldDto[]> {
        return this._currentUser$.asObservable().pipe(
            filter(response => response !== null && response !== undefined),
            map(user => {
                return user && user.CustomFields ? user.CustomFields.filter(x => fieldExtIDs.some(z => x.ExtID === z)) : [];
            })
        );
    }

    public getConfigByUserField(): Observable<CaracConfig[]> {
        return this._store.select(selectCaracsExtIds).pipe(
            concatLatestFrom(() => this._store.select(selectAllDicoCaracConfigs)),
            map(([userFields, configs]) => {
                const productReferenceCaracType = configs?.data?.filter(carac => 
                    carac.DicoCarac.TypeCarac === NPElementType.Product.toString() || 
                    carac.DicoCarac.TypeCarac === NPElementType.Reference.toString()
                );
                return _.compact(userFields.map(fieldValue => 
                    productReferenceCaracType.find(config => config.DicoCaracExtID === fieldValue)
                ));
            })
        );
    }
}
