import {Injectable} from '@angular/core';
import {NpInstanceService} from '@data/services';
import {Router} from '@angular/router';
import {RoutePortalsName} from '../../constants/route-portals.constants';
import {EntityName} from '../../../../lib/data/model/portal';
import {NP_ENTITY_CONFIG} from '../../constants/np-entity.constants';
import { RoutePagesName } from '../../constants/route-pages.constants';
import { TreeNodeCatalog } from '../../models';

@Injectable({
    providedIn: 'root'
})
export class RouteResolverService {

    constructor(private _npInstanceService: NpInstanceService, private _router: Router) {
    }

    navigate(routeName: RoutePortalsName | RoutePagesName) {
        return this._router.navigate([routeName, this._npInstanceService.instanceId]);
    }

    /**
     *  Navigating within default portal
     *  @param subRouteName: child route name
     */
    navigateToDefaultEntityRoute(subRouteName: RoutePortalsName | RoutePagesName) {
        return this._router.navigate(
            [
                this._npInstanceService.instance.defaultEntityRoute,
                this._npInstanceService.instanceId,
                subRouteName
            ]
        );
    }

    /**
     *  Navigating within default portal
     *  @param subRouteName: child route name
     */
    navigateToCurrentEntityRoute(subRouteName: RoutePortalsName | RoutePagesName) {
        return this._router.navigate(
            [
                this._npInstanceService.currentEntity.routeName,
                this._npInstanceService.instanceId,
                subRouteName
            ]
        );
    }

    navigateToCurrentEntityRouteWithParams(subRouteName: RoutePortalsName | RoutePagesName, params: any, isCreation: boolean = false, catalogTreeNode?: TreeNodeCatalog) {
        return this._router.navigate(
            [
                this._npInstanceService.currentEntity.routeName,
                this._npInstanceService.instanceId,
                subRouteName
            ],
            { 
                queryParams: params, 
                state: {
                    isCreation,
                    catalogTreeNode
                }
            }
        );
    }

    setEntityAndNavigateTo(entityName: EntityName, routeName: RoutePortalsName | RoutePagesName) {
        if (NP_ENTITY_CONFIG.has(entityName) && routeName) {
            this._npInstanceService.currentEntity = NP_ENTITY_CONFIG.get(entityName);
            return this.navigateToCurrentEntityRoute(routeName);
        }
        return this._router.navigate([RoutePortalsName.NO_INSTANCE_FOUND]);
    }
}
