import {createFeatureSelector, createSelector} from '@ngrx/store';
import {characteristicsFeatureKey, CharacteristicState} from './characteristics.reducer';
import * as _ from 'lodash';
import {NpElementHelper} from '../../data/helpers/np-element-helper';

const selectCharacteristicsStateState =
    createFeatureSelector<CharacteristicState>(characteristicsFeatureKey);

// Returns all dicoCaracs
export const selectAllDicoCharacteristics = createSelector(
    selectCharacteristicsStateState,
    state => state.dicoCharacteristics
);

// Returns all caracConfigs
export const selectAllDicoCaracConfigs = createSelector(
    selectCharacteristicsStateState,
    state =>
        NpElementHelper.rebuildConfigs(state.dicoCharacteristics)
);

// Returns dicoCaracs by extIds
export const selectDicoCaracsByExtIds = (dicoCaraExtIds: string[]) => createSelector(
    selectCharacteristicsStateState,
    state => _.isEmpty(dicoCaraExtIds) ?
        [] :
        _.filter(state.dicoCharacteristics, (item) =>
            dicoCaraExtIds.some(extId => item.ExtID === extId))
);

// Returns caracConfigs by extIds
export const selectDicoCaracConfigsByExtIds = (dicoCaraExtIds: string[]) => createSelector(
    selectCharacteristicsStateState,
    state => _.isEmpty(dicoCaraExtIds) ?
        {data: [], links: [[]]} :
        NpElementHelper.rebuildConfigs(
            _.filter(state.dicoCharacteristics, (item) =>
                dicoCaraExtIds.some(extId => item.ExtID === extId))
        )
);
