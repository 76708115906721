import {Injectable, OnDestroy} from '@angular/core';
import {combineLatest, Observable, of, Subscription, throwError} from 'rxjs';
import {map, mergeMap, switchMap} from 'rxjs/operators';
import {
    DicoCarac,
    ElementRepository,
    FileReaderService,
    NPCaracLien,
    NPCaracListe,
    NPCaracValeur,
    NPDicoCarac,
    NPElement,
    NPElementType,
    NPSearchResult,
    SearchRepository,
} from '@nextpage/np-sdk-data';

import {ElementLabels, SpDicoCarac} from '@data/constants';
import {Characteristic, CharTemplateDto, MediaSummary, ParamsFilter, ProductSummary} from '@app/models';
import {AdvancedSearchDTOBuilder, AdvSearchArgsBuilder, FamilyBuilder, FiltersBuilder} from '@data/builders';
import {ChoiceCriteria, Field, ProductSummaryWithTotalRow} from '@data/types';
import {ProductService, SpUserInfoService, TableHandlerService} from '@data/services';
import {LinkedProductItem} from '../models/media.model';
import {CharTemplateService} from '../../../lib/data/services/char-template.service';
import {selectAllDicoCharacteristics} from '@store/characteristics';
import {Store} from '@ngrx/store';
import * as _ from 'lodash';
import {selectElementPreviewConfigs, selectUVisualCaracValue} from 'src/app/store/selector/user-info.selectors';
import {ParamsFilterBuilder} from '../builders';
import {AdvancedSearchDTO, Chars, Facet} from '../types';
import {AdvSearchArgs} from 'src/lib';
import {environment} from '@env';
import {UserInfoFacade} from './user-info.facade';

@Injectable({
    providedIn: 'root',
})
export class ProductsFacade implements OnDestroy {
    private extIdVisuel: string;
    private _extIdVisualSub: Subscription;

    constructor(
        private _userInfoService: SpUserInfoService,
        private _elementRepository: ElementRepository,
        private _searchRepository: SearchRepository,
        private _fileReaderService: FileReaderService,
        private _productService: ProductService,
        private _charTemplateService: CharTemplateService,
        private _store: Store,
        private _userInfoFacade: UserInfoFacade
    ) {
        this._extIdVisualSub = this._store.select(selectUVisualCaracValue).subscribe(value => {
            this.extIdVisuel = value;
        });
    }

    public init() {
        this._userInfoService.getUserCustomFieldByExtId(SpDicoCarac.CP_EXT_ID_VISUEL)
            .pipe(
                switchMap(userFields => {
                    return userFields && userFields.Values?.length > 0 ? of(userFields.Values[0].Value) : throwError(`${SpDicoCarac.CP_EXT_ID_VISUEL} no found.`);
                })
            )
            .subscribe(visualCaracExtId => {
                this.extIdVisuel = visualCaracExtId;
            }, error => console.error(error));
    }

    public fetchData(paramsFilter: ParamsFilter): Observable<ProductSummaryWithTotalRow> {
        return this._searchElements(paramsFilter)
            .pipe(
                map((npSearchResult: NPSearchResult) =>
                    ({
                        productSummaries: npSearchResult.elements.map(element => this.npElementToProductSummary(element)),
                        totalsRows: npSearchResult.resultsCount
                    } as ProductSummaryWithTotalRow)
                )
            );
    }

    public fetchElementIds(paramsFilter: ParamsFilter): Observable<number[]> {
        return this._searchElements(paramsFilter)
            .pipe(
                map((npSearchResult: NPSearchResult) => npSearchResult.elements.map(element => element.ID))
            );
    }

    public fetchElementExtIds(paramsFilter: ParamsFilter): Observable<string[]> {
        return this._searchElements(paramsFilter)
            .pipe(
                map((npSearchResult: NPSearchResult) => npSearchResult.elements.map(element => element.ExtID))
            );
    }


    // TODO: A factoriser!
    getInfoForProduct(productSummary: ProductSummary) {
        return combineLatest([
            this.getCharacteristicForParent(productSummary.element),
            this.getCharacteristicForProductSummary(productSummary.element, SpDicoCarac.CP_LISTE_EXTID_CARAC),
            this._productService.getCharacteristicConfigListByUser(SpDicoCarac.CP_LISTE_EXTID_CARAC as string),
            this._store.select(selectAllDicoCharacteristics)
        ]).pipe(
            map(([mapCaracForParent, mapCaracProduct, characteristicList, dicoCaracs]) =>
                this.getFields(mapCaracForParent, mapCaracProduct, characteristicList, dicoCaracs, productSummary.element)
            )
        );
    }

    getInfoForMedia(productSummary: ProductSummary) {
        return combineLatest([
            this.getCharacteristicForProductSummary(productSummary.element, SpDicoCarac.CP_LISTE_EXTID_CARAC_MEDIA),
            this._productService.getCharacteristicConfigListByUser(SpDicoCarac.CP_LISTE_EXTID_CARAC_MEDIA as string),
            this._store.select(selectAllDicoCharacteristics)
        ]).pipe(
            map(([mapCaracProduct, characteristicList, dicoCaracs]) =>
                this.getFields(null, mapCaracProduct, characteristicList, dicoCaracs, productSummary.element)
            )
        );
    }
    getInfoForUpdateMedia(mediaSummary: MediaSummary) {
        return combineLatest([
            this.getCharacteristicForProductSummary(mediaSummary.element, SpDicoCarac.CP_LISTE_EXTID_CARAC_MEDIA),
            this._productService.getCharacteristicConfigListByUser(SpDicoCarac.CP_LISTE_EXTID_CARAC_MEDIA as string),
            this._store.select(selectAllDicoCharacteristics)
        ]).pipe(
            map(([mapCaracProduct, characteristicList, dicoCaracs]) =>
                this.getFields(null, mapCaracProduct, characteristicList, dicoCaracs, mediaSummary.element)
            )
        );
    }

    public completeChoiceCriteriaWithFacets(
        choiceCriteriaList: ChoiceCriteria[],
        productTypeId?: number,
        langID?: number,
        chars?: Chars,
        scope?: any,
        advancedSearchConfig?: AdvancedSearchDTO
    ): Observable<ChoiceCriteria[]> {
        const facetsList: string[] = !_.isEmpty(choiceCriteriaList) ? choiceCriteriaList?.map(data => data?.ext_id) : [];
        if (facetsList.length > 0) {
            const paramsFilter = new ParamsFilterBuilder()
                .withElementTypes(advancedSearchConfig ? advancedSearchConfig.Filters.ElementTypes : [environment.instance.elementType])
                .withFacetList(facetsList.filter(facet => !_.isNil(facet)))
                .withProductTypeId(productTypeId)
                .withChars(chars)
                .withScope(scope)
                .withLangID(langID ? langID : 1)
                .build();

            return this.generateFiltersFind(paramsFilter, advancedSearchConfig)
                .pipe(
                    mergeMap(advSearchArgs => {
                        return this._searchRepository.searchFacetsWithMultipleOperators(advSearchArgs).pipe(
                            map((facets: Facet[]) => {
                                return choiceCriteriaList.map(choiceCriteria => {
                                    const facetsByExtId = facets.filter(facet => facet.DCExtID === choiceCriteria.ext_id);
                                    facetsByExtId.forEach(facet => {
                                        facet.DicoCaracID = choiceCriteria.DicoCaracID;
                                        facet.typeCode = choiceCriteria.typeCode;
                                    });
                                    return {
                                        ...choiceCriteria,
                                        facets: _.sortBy(facetsByExtId, 'Value'),
                                    };
                                });
                            })
                        );
                    })
                );

        } else {
            return of([]);
        }
    }

    /**
     * Returns charTemplates by advanced search result
     * @param paramsFilter
     * @param AdvancedSearchConfig
     */
    public getCharTemplatesByAdvSearch(paramsFilter: ParamsFilter, AdvancedSearchConfig?: AdvancedSearchDTO) {
        return this.generateFiltersFind(paramsFilter, AdvancedSearchConfig)
            .pipe(
                switchMap(advSearch => {
                    return combineLatest([
                            this._charTemplateService.getByAdvSearch(advSearch),
                            this._store.select(selectAllDicoCharacteristics)
                        ]
                    );
                }),
                map(([charTemplateDtoList, dicoCarac]) => {
                    const filteredCharTemplateDtoListWithChoiceCriterias = charTemplateDtoList.filter(
                        (charTemplateDto: CharTemplateDto) => charTemplateDto.CharTemplateChoiceCriterias.length > 0
                    );
                    return filteredCharTemplateDtoListWithChoiceCriterias.map((charTemplateDto: CharTemplateDto) => {
                        return {
                            ...charTemplateDto,
                            CharTemplateChoiceCriterias: this.completeChoiceCriteriaWithLabelAndExtId(
                                charTemplateDto.CharTemplateChoiceCriterias,
                                dicoCarac
                            ),
                        } as CharTemplateDto;
                    });
                })
            );
    }

    // TODO: Je ne comprends pas bien le but de cette méthode (A revoir)
    private getCharacteristicForParent(element: NPElement): Observable<Map<string, NPCaracValeur>> {
        if (element.ElementType === NPElementType.Product) {
            return of(new Map());
        }
        const parentExtId = element.ParentExtID;

        return this._userInfoFacade.getCaracsPreview()
            .pipe(
                mergeMap(result => {
                    return this._elementRepository.getElementWithProperties(
                        [parentExtId],
                        result.paths,
                        result.dicoCaracs.map(characteristic => characteristic.ExtID)
                    );
                }),
                map(elementMap => elementMap.get(parentExtId)?.Values)
            );
    }

    // TODO: Méthode presque unitile
    private getCharacteristicForProductSummary(element: NPElement, userField: string): Observable<Map<string, NPCaracValeur>> {
        const productValuesMap = element.Values;
        return this._productService.getCharacteristicConfigListByUser(userField).pipe(
            map((characteristicList: Characteristic[]) => {
                const characteristicMap = new Map<string, NPCaracValeur>();
                for (const characteristic of characteristicList) {
                    const nbCaracValeur = productValuesMap.get(characteristic.ExtID);
                    if (nbCaracValeur) {
                        characteristicMap.set(characteristic.ExtID, nbCaracValeur);
                    }
                }
                return characteristicMap;
            })
        );
    }

    private _searchElements = (paramsFilter: ParamsFilter): Observable<NPSearchResult> =>
        this.generateFiltersFind(paramsFilter)
            .pipe(
                mergeMap(advSearchArgs => {
                    return this._searchRepository.SearchWithMultipleOperators(advSearchArgs)
                        .pipe(
                            map(nPSearchResult => {
                                return {
                                    ...nPSearchResult,
                                    elements: nPSearchResult.elements,
                                } as NPSearchResult;
                            })
                        );
                })
            )

    public getImageUrl = (element: NPElement, extId?: string) =>
        this._fileReaderService.toFilePath(this.getImageUrlWithoutToken(element, extId))

    public getImageUrlWithoutToken(element: NPElement, extId?: string): string {
        const imageExtId = extId ?? this.extIdVisuel;
        if (element.ElementType === NPElementType.Media) {
            return element.getValueTextValue(DicoCarac.MEDIA_FILE);
        }
        let imageUrl = '';
        const caracValue = element.getValue(imageExtId);

        // Carac de type Lien Media
        if (caracValue instanceof NPCaracLien) {
            const rebuildLinkImages = element?.getValueLien(imageExtId)?.RebuildLinkedElements;
            if (rebuildLinkImages && rebuildLinkImages.length > 0) {
                imageUrl = rebuildLinkImages[0]?.Element?.getValueTextValue(DicoCarac.MEDIA_FILE);
            }
            return imageUrl;

        } else {
            imageUrl = [imageExtId].reduce((url, key) => {
                const value = element?.getValueTextValue(key);
                return url || value;
            }, '');
            return imageUrl;
        }
    }

    private getPdfUrl(pdfUrl): string {
        return this._fileReaderService.toFilePathToDownload(pdfUrl) ?? '';
    }

    private npElementToProductSummary(element: NPElement): ProductSummary {
        const lastModifiedInfos = TableHandlerService.getLastModifiedInfos(element);
        return new ProductSummary({
            label: element.getValueTextValue(ElementLabels[element.ElementType]),
            modificationDate: TableHandlerService.toLocalDate(lastModifiedInfos),
            element: element,
            lastModificationUserName: lastModifiedInfos ? lastModifiedInfos['ModifiedByUserName'] : '',
            imageUrl: this.getImageUrl(element),
            urlWithoutToken: this.getImageUrlWithoutToken(element)
        });
    }


    private generateFiltersFind(paramsFilter: ParamsFilter, advancedSearchConfig?: AdvancedSearchDTO): Observable<AdvSearchArgs> {
        const filters = new FiltersBuilder()
            .withKeywords(paramsFilter?.keyword)
            .withProductsTypeID(paramsFilter?.productTypeId)
            .withElementTypes(paramsFilter?.elementTypeList)
            .withChars(paramsFilter?.chars);

        // Récupération du parent (Famille ou Canal)
        const parent = new FamilyBuilder().withID(0).withLabel('');
        if (!!paramsFilter?.channelScope && paramsFilter?.channelScope !== 0) {
            parent.withID(paramsFilter?.channelScope);
            filters.withChannel(parent.build());
        } else if (!!paramsFilter?.familyScope && paramsFilter?.familyScope !== 0) {
            parent.withID(paramsFilter?.familyScope);
            filters.withFamily(parent.build());
        }

        const advancedSearchDTO = advancedSearchConfig ? advancedSearchConfig : new AdvancedSearchDTOBuilder()
            .withSortFields(paramsFilter?.sortFields || [])
            .withFilters(filters.build())
            .build();

        return combineLatest([
            this._store.select(selectElementPreviewConfigs),
            this._store.select(selectAllDicoCharacteristics)
        ]).pipe(
            map(([previewParams, dicoCaracs]) => {
                const userDicoCaracs = previewParams.previewCaracs.map(previewCarac => {
                    return dicoCaracs.find(dicoCarac => dicoCarac.ExtID === previewCarac);
                });

                const paths = userDicoCaracs
                    .filter(characteristic => characteristic?.ExtID !== previewParams.mainVisualCaracExtId && characteristic?.TypeCode.includes('LIEN'))
                    .map(characteristic => [characteristic.ExtID, previewParams.mainVisualCaracExtId]);

                paths.push([previewParams.mainVisualCaracExtId]);

                return new AdvSearchArgsBuilder()
                    .withPageSize(paramsFilter?.numberOfElementByPage)
                    .withCurrentPage(paramsFilter?.page)
                    .withPaths(advancedSearchConfig ? [[]] : paths)
                    .withDCExtIDs(paramsFilter?.fieldsToDisplay)
                    .withFacets(paramsFilter?.facetsList)
                    .withConfig(advancedSearchDTO)
                    .withLangID(paramsFilter?.langID)
                    .build();
                }
            )
        );
    }

    // TODO: Méthode unitile
    private getFields(
        mapCaracForParent: Map<string, NPCaracValeur> | null,
        mapCaracProduct: Map<string, NPCaracValeur>,
        characteristicList: Characteristic[],
        dicoCaracList: NPDicoCarac[],
        element: NPElement
    ) {
        const combineCharacteristicUser = new Map([
            ...(mapCaracForParent || new Map()),
            ...mapCaracProduct
        ]);
        const fields: Field[] = [];
        const linkedCaracs = characteristicList.filter(carac => carac.typeCode.indexOf('LIEN') !== -1);
        const linkedElementsProducts = linkedCaracs.reduce((acc: Map<string, LinkedProductItem[]>, carac) => {
            const linkedElements = this.getLinkedProductsItems(element?.getValueLien(carac?.ExtID)?.RebuildLinkedElements?.map(result => result?.Element) || []);
            if (!acc.has(carac.label)) {
                acc.set(carac.label, []);
            }
            acc.get(carac.label).push(...linkedElements);
            return acc;
        }, new Map<string, LinkedProductItem[]>());

        for (const characteristic of characteristicList) {
            const dicoCaracForChoiceCriteria = dicoCaracList?.find(dicoCarac => dicoCarac.ExtID === characteristic.ExtID);
            const descriptif = combineCharacteristicUser.get(characteristic.ExtID);
            const regexPdf = /\.(pdf)$/;
            if (descriptif) {
                if (descriptif.TypeValue === 0) {
                    fields.push({
                        label: characteristic.label,
                        value: descriptif.Value,
                        pdf: descriptif.Value.match(regexPdf) ? this.getPdfUrl(descriptif.Value) : null,
                        unit: dicoCaracForChoiceCriteria?.Unite,
                    });
                } else if (descriptif.TypeValue === 2) {
                    const characteristics: NPCaracListe = descriptif as NPCaracListe;
                    const pdfValues = characteristics.Values.map(charac => charac.Label.match(regexPdf) ? this.getPdfUrl(charac.Label) : null).filter(value => !!value);
                    fields.push({
                        label: characteristic.label,
                        value: characteristics.Values.map(charac => charac.Label),
                        pdf: pdfValues.length > 0 ? pdfValues : null,
                        unit: dicoCaracForChoiceCriteria?.Unite
                    });
                }
            }
        }
        return ({fields, linkedElementsProducts, parent: mapCaracForParent?.keys().next().value});
    }

    private completeChoiceCriteriaWithLabelAndExtId(choiceCriteriaList: ChoiceCriteria[], dicoCaracList: NPDicoCarac[]): ChoiceCriteria[] {
        return choiceCriteriaList.map<ChoiceCriteria>(choiceCriteria => {
            const dicoCaracForChoiceCriteria = dicoCaracList.filter(dicoCarac => dicoCarac.ID === choiceCriteria.DicoCaracID)[0];
            if (dicoCaracForChoiceCriteria) {
                return {
                    ...choiceCriteria,
                    label: dicoCaracForChoiceCriteria.Name,
                    ext_id: dicoCaracForChoiceCriteria.ExtID,
                    typeCode: dicoCaracForChoiceCriteria.TypeCode,
                    dicoCaracExtId: dicoCaracForChoiceCriteria.ExtID
                };
            }
        });
    }

    getLinkedProductsItems(element: NPElement[]): LinkedProductItem[] {
        return element.map(elem => ({
            imageUrl: this.getImageUrl(elem),
            label: elem.getValueTextValue(DicoCarac.PRODUCT_LABEL)
        }));
    }

    fetchElementsByAdvancedSearch(advSearchArgs: AdvSearchArgs) {
        return this._searchRepository.Search(advSearchArgs)
            .pipe(
                mergeMap((npSearchResult: NPSearchResult) => {
                    const result = ({
                        productSummaries: npSearchResult.elements.map(element => this.npElementToProductSummary(element)),
                        totalsRows: npSearchResult.resultsCount
                    } as ProductSummaryWithTotalRow);

                    // Cas des Références
                    if (environment?.instance?.elementType === NPElementType.Reference) {
                        // Récupération des références qui n'ont pas la carac mainVisual
                        const referencesWithoutMainVisual = result.productSummaries
                            .filter(productSummary => _.isNil(productSummary.imageUrl) || _.isEmpty(productSummary.imageUrl));

                        if (!_.isEmpty(referencesWithoutMainVisual)) {
                            // récupération de mainVisual des parents (Produits)
                            return this.assignMainVisualFromParent(referencesWithoutMainVisual)
                                .pipe(map(() => result));
                        }
                    }

                    return of(result);
                }),
            );
    }

    ngOnDestroy(): void {
        this._extIdVisualSub?.unsubscribe();
    }

    /**
     * Assigne le visuel principal aux Références qui n'en possèdent pas
     * @param productSummaries
     * @private
     */
    public assignMainVisualFromParent(
        productSummaries: ProductSummary[]
    ) {
        const parentExtIds = productSummaries.map(productSummary => productSummary.element.ParentExtID);

        return this.getElements(
            parentExtIds,
            [[this.extIdVisuel]],
            [this.extIdVisuel, DicoCarac.MEDIA_LABEL, DicoCarac.MEDIA_FILE]
        ).pipe(
            map(parentMap => {
                if (parentMap?.size > 0) {
                    productSummaries.forEach(reference => {
                        if ((_.isNil(reference.imageUrl) || _.isEmpty(reference.imageUrl))
                            && parentMap.has(reference.element.ParentExtID)) {
                            reference.imageUrl = this.getImageUrl(parentMap.get(reference.element.ParentExtID));
                        }
                    });
                }
                return of(true);
            })
        );
    }

    public getElements(
        elementIDs: string[],
        linksPath: string[][],
        dicoCaracs: string[] = []
    ): Observable<Map<string, NPElement>> {
        return this._elementRepository.getElementWithProperties(_.uniq(elementIDs), linksPath, dicoCaracs);
    }
}
