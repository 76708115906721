import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {ProductsFacade} from '@data/facades';
import {DicoCarac} from '@lib/models';
import {map, mergeMap} from 'rxjs/operators';
import {ParamsFilterBuilder} from '@data/builders';
import {Store} from '@ngrx/store';
import {selectExportSelection} from '@data/selection';
import {iif, Observable, of} from 'rxjs';
import {cloneAdvSearchParam} from '@np/utils';

@Injectable({
    providedIn: 'root',
})
export class ElementSelectionService {

    constructor(
        private readonly _productsFacade: ProductsFacade,
        private readonly _store: Store) {
    }

    public getSelectedElementIds(advSearchParams: ParamsFilterBuilder, fieldsToDisplay: DicoCarac[]): Observable<number[]> {
        return this._store.select(selectExportSelection).pipe(
            mergeMap(exportSelection =>
                iif(
                    () => !_.isEmpty(exportSelection.elementIds),
                    // Case of a few selected Elements
                    of(exportSelection.elementIds),
                    // Get all scope Elements
                    this._getAllElementIds(advSearchParams, exportSelection.excludedElementIds ?? [], fieldsToDisplay)
                )
            )
        );
    }

    private _getAllElementIds(advSearchParam: ParamsFilterBuilder, excludeElementIds: number[], fieldsToDisplay: DicoCarac[]): Observable<number[]> {
        const clonedParam = cloneAdvSearchParam(advSearchParam, {fieldsToDisplay: fieldsToDisplay});
        return this._productsFacade.fetchElementIds(clonedParam.build()).pipe(
            map(allElementIds => this._filterExcludedIds(allElementIds, excludeElementIds)),
            map(elementIds => (_.isEmpty(elementIds) ? null : elementIds))
        );
    }

    private _filterExcludedIds(allElementIds: number[], excludeElementIds: number[]): number[] {
        return _.isEmpty(excludeElementIds)
            ? allElementIds
            // Exclusion of unchecked Elements
            : allElementIds.filter(elementId => !excludeElementIds.includes(elementId));
    }
}
