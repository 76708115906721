<div>
    <h3>{{ 'import.import-media' | translate }}</h3>
</div>
<mat-divider class="my-3"></mat-divider>
<div class="stepper-content">
    <div class="title-check">
        <h5 class="stepper-title">{{ 'import.import-media' | translate }}</h5>
        <mat-icon [ngStyle]="{
                color: filesList.length > 0 ? 'green' : 'red'
              }">
            {{ filesList.length > 0 ? "check" : "close" }}
        </mat-icon>
    </div>
    <div class="dropzone-group">
        <div class="dropzone fullscreen" fileDrop
                (filesDropped)="handleDrop($event[0])"
                (filesHovered)="handleHover($event)"
                [class.hovered]="dropZoneHovered">
            <div>
                <div class="dropzone-info">
                    <span class="selected-file">
                        {{ filesList.length === 0 ? ("import.no-selected-file" | translate) : '' }}
                    </span>
                    <ng-container *ngIf="filesList.length > 0">
                        <span *ngFor="let file of filesList; let i = index" class="selected-file">
                            {{ file.name }}<span *ngIf="i < filesList.length - 1">, </span>
                        </span>
                    </ng-container>
                </div>
                <div class="file-list">
                    <div *ngFor="let uploadImage of uploadImages" class="drop-zone-file-container">
                        <div class="image-preview">
                            <img [src]="uploadImage.imgURL" *ngIf="uploadImage.imgURL">
                        </div>
                        <span class="dz-remove">
                          <a (click)="removeFile(uploadImage, $event)">{{ 'medias.delete-media' | translate }}</a>
                    </span>
                    </div>
                </div>
                <input
                    #inputFile
                    style="display: none"
                    type="file"
                    multiple="multiple"
                    (change)="onFileChange($event)"/>

                <div class="input-file-block">
                    <p>{{ 'import.drag-file' | translate }}</p>
                    <p>{{ 'common.or' | translate }}</p>
                    <button mat-raised-button (click)="inputFile.click()">
                        <i class="pr-1 pi pi-download"></i>
                        {{ 'medias.upload-media' | translate }}
                    </button>
                </div>
            </div>
            <ng-container *ngIf="fileError">
                <div class="file-error-block">
                    <span class="glyphicon glyphicon-ban-circle"></span>
                    <span>{{ 'import.incorrect-file-extension' | translate }}</span>
                </div>
            </ng-container>
            <div *ngIf="uploadImages && uploadImages.length > 0">
                <button mat-raised-button
                        [disabled]="isSaving$ | async"
                        (click)="uploadAllFiles()"
                >
                    <div class="is-saving">
                        <mat-spinner *ngIf="isSaving$ | async" diameter="20" strokeWidth="3"></mat-spinner>
                        <span>{{ 'medias.validate-upload' | translate }}</span>
                    </div>
                </button>
            </div>
        </div>
        <div class="dropzone fullscreen url-media">
            <mat-form-field appearance="outline" class="field-search-custom">
                <mat-label>{{ 'import.url-import' | translate }}</mat-label>
                <input [(ngModel)]="urlMedia" type="text" matInput/>
            </mat-form-field>
            <i class="pr-1 pi pi-download url-media" (click)="createMediaByExternalUrl()"></i>
        </div>
    </div>
</div>

