import {Injectable} from '@angular/core';

import {Actions, concatLatestFrom, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {
    ExecuteElementsExport,
    ExecuteElementsExportFailure,
    ExecuteElementsExportSuccess
} from './elements-export.actions';
import {ElementsExportService} from '../../data/services/elements-export.service';
import {Store} from '@ngrx/store';
import {selectUserCustomFieldValue, selectUserInfoForm} from '../selector/user-info.selectors';
import {MessageService} from '@data/core';
import {MessageModel, Messages} from '../../core';
import {SpDicoCarac} from '@data/constants';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ElementsExportEffect {
    constructor(private actions$: Actions,
                private _store: Store,
                private _elementsExportService: ElementsExportService,
                private _messageService: MessageService,
                private _translate: TranslateService) {
    }

    elementsExport$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ExecuteElementsExport),
            concatLatestFrom(() => [
                this._store.select(selectUserInfoForm),
                this._store.select(selectUserCustomFieldValue(SpDicoCarac.CP_PROFILE_EXPORT)),
            ]),
            switchMap(([action, userInfo, exportProfileExtId]) => {
                    return this._elementsExportService.buildAndExecuteExport(
                        {
                            ...action.payload,
                            currentUser: userInfo,
                            exportProfile: exportProfileExtId
                        }
                    )
                        .pipe(
                            map(exported => {
                                this._messageService.display(this._translate.instant(Messages.EXPORT_SUCCESS));
                                return ExecuteElementsExportSuccess({exported});
                            })
                        );
                }
            ),
            catchError((error: MessageModel ) => {
                if (error?.summary) {
                    this._messageService.error(error.summary, error.detail, error.severity);
                } else {
                    console.error(error);
                }
                return of(ExecuteElementsExportFailure({message: error?.toString()}));
            })
        );
    });
}
