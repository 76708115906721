import {Injectable, Input} from '@angular/core';

import {Actions, concatLatestFrom, createEffect, ofType} from '@ngrx/effects';
import {catchError, filter, map, mergeMap, tap} from 'rxjs/operators';
import {
    createMediaByExternalUrl,
    createMediaByExternalUrlFailure,
    createMediaByExternalUrlSuccess,
    SearchMedia,
    SearchMediaFailure,
    SearchMediaSuccess, StopSaving,
    UpdateMedia,
    UpdateMediaFailure,
    UpdateMediaSuccess,
    UploadFileFailure,
    UploadFiles,
    UploadFileSuccess,
} from '@store/action';
import {MediaFacade} from '@data/facades';
import {of} from 'rxjs';
import {Store} from '@ngrx/store';
import {selectMediaFolderId, selectMediaPreviewConfigs} from '../selector';
import * as _ from 'lodash';
import {AdvSearchArgs, ElementWriterService} from 'src/lib';
import {DialogService} from '../../graphics/services/dialog.service';

@Injectable()
export class MediaLibraryEffects {
    constructor(private actions$: Actions,
                private _store: Store,
                private _mediaFacade: MediaFacade,
                private _elementWriter: ElementWriterService,
                private _dialogService: DialogService) {
    }

    searchMedia$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SearchMedia),
            concatLatestFrom(() => [
                this._store.select(selectMediaPreviewConfigs)
                    .pipe(filter(result => !!result.mainVisualCaracExtId)),
            ]),
            mergeMap((
                [
                    action,
                    mediaPreviewParams
                ]) => {
                    const _advSearch = _.cloneDeep<AdvSearchArgs>(action.advancedSearch);
                    _advSearch.DCExtIDs = mediaPreviewParams.previewCaracs;
                    return this._mediaFacade.search(_advSearch, action.multiOperators)
                        .pipe(
                            map(searchResult => SearchMediaSuccess({searchResult}))
                        );
                }
            ),
            catchError(error => of(SearchMediaFailure({message: error?.toString()})))
        );
    });

    uploadFiles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UploadFiles),
            concatLatestFrom(() => this._store.select(selectMediaFolderId)),
            mergeMap(([action, mediaFolderId]) => {
                if (!mediaFolderId) {
                    return of(UploadFileFailure({ error: 'Folder ID is missing' }));
                }
                return this._elementWriter.createTableOfMedias(action.uploadImages.map(item => item.file), mediaFolderId)
                    .pipe(
                        map(file => UploadFileSuccess({ file })),
                );
            }),
            catchError(error => of(UploadFileFailure({ error })))
        )
    );

    createMediaByExternalUrl$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createMediaByExternalUrl),
            concatLatestFrom(() => this._store.select(selectMediaFolderId)),
            mergeMap(([action, mediaFolderId]) => {
                if (!mediaFolderId) {
                    return of(UploadFileFailure({ error: 'Folder ID is missing' }));
                }
                return this._elementWriter.createMediaByExternalUrl(action.url, mediaFolderId)
                    .pipe(
                        map(() => createMediaByExternalUrlSuccess( {url: ''} )),
                    );
            }),
            catchError(error => {
                return of(UploadFileFailure({ error }));
            })
        )
    );

    uploadSuccess$ = createEffect(
        () => this.actions$.pipe(
            ofType(UploadFileSuccess),
            tap(() => {
                this._dialogService.showSuccessMessage('medias.message-upload-success');
                this._store.dispatch(StopSaving());
                setTimeout(() => { window.location.reload(); }, 2000);
            })
        ),
        {dispatch: false}
    );

    uploadFailure$ = createEffect(
        () => this.actions$.pipe(
            ofType(UploadFileFailure),
            tap(() => {
                this._dialogService.showErrorMessage('medias.message-upload-failure');
            })
        ),
        {dispatch: false}
    );

    createMediaByExternalUrlSuccess$ = createEffect(
        () => this.actions$.pipe(
            ofType(createMediaByExternalUrlSuccess),
            tap(() => {
                this._dialogService.showSuccessMessage('medias.message-url-media-upload-success');
            })
        ),
        {dispatch: false}
    );

    createMediaByExternalUrlFailure$ = createEffect(
        () => this.actions$.pipe(
            ofType(createMediaByExternalUrlFailure),
            tap(() => {
                this._dialogService.showErrorMessage('medias.message-url-media-upload-failure');
            })
        ),
        {dispatch: false}
    );

    updateMediaEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UpdateMedia),
            mergeMap(action => {
                const {uploadMedia, media} = action;
                const mediaId = media.element.ID;
                const mediaFolderId = media.element.ParentID;
                return this._elementWriter.updateMedia(uploadMedia, mediaFolderId, mediaId).pipe(
                    map(file => UpdateMediaSuccess({uploadMedia: file})),
                );
            }),
            catchError(error => {
               return of(UpdateMediaFailure({error}));
            })
        )
    );

    updateMediaSuccess$ = createEffect(
        () => this.actions$.pipe(
            ofType(UpdateMediaSuccess),
            tap(() => {
                this._dialogService.showSuccessMessage('medias.message-media-update-success');
            })
        ),
        {dispatch: false}
    );

    updateMediaFailure$ = createEffect(
        () => this.actions$.pipe(
            ofType(UpdateMediaFailure),
            tap(() => {
                this._dialogService.showErrorMessage('medias.message-media-update-failure');
            })
        ),
        {dispatch: false}
    );
}
