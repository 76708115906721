import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AccountPageComponent} from './viewsModules/common/account-page/account-page.component';
import {AuthenticationComponent} from './viewsModules/shared/authentication/authentication.component';
import {HomePageComponent} from './viewsModules/common/home-page/home-page.component';
import {ImportPageComponent} from './viewsModules/common/import-page/import-page.component';
import {MainPageComponent} from './viewsModules/common/main-page/main-page.component';
import {ProductsPageComponent} from './viewsModules/common/products-page/products-page.component';
import {FavoritePageComponent} from './viewsModules/common/favorite-page/favorite-page.component';
import {AuthenticationGuard} from './data/guards/authentication.guard';
import {RoutePortalsName, RouteParamName} from './data/constants/route-portals.constants';
import {MediasPageComponent} from './viewsModules/common/medias-page/medias-page.component';
import {NpInstanceGuard} from './data/services/guards/np-instance.guard';
import {NoInstanceFoundComponent} from './viewsModules/shared/no-instance-found/no-instance-found.component';
import {NpEntityGuard} from './data/services/guards/np-entity.guard';
import {EntityResolver} from './data/services/resolvers/entity-resolver';
import {NpHomePageComponent} from './viewsModules/common/np-home-page/np-home-page.component';
import {PageResolver} from './data/services/resolvers/page-resolver';
import { PublicationHomepageComponent } from './viewsModules/publication/homepage/publication-homepage.component';
import { PublicationCataloguePageComponent } from '@view/publication';
import { PublicationMainPageComponent } from './viewsModules/publication/publication-main-page/publication-main-page.component';
import { RoutePagesName } from './data/constants/route-pages.constants';
import {ImportMediaPageComponent} from './viewsModules/common/import-media-page/import-media-page.component';

const routes: Routes = [
    {
        path: `${RoutePagesName.LOGIN}/:${RouteParamName.INSTANCE_ID}`,
        component: AuthenticationComponent,
        canActivate: [NpInstanceGuard]
    },
    {
        path: `${RoutePortalsName.CONSULTATION}/:${RouteParamName.INSTANCE_ID}`,
        component: MainPageComponent,
        canActivate: [AuthenticationGuard, NpInstanceGuard, NpEntityGuard],
        resolve: {entityParameter: EntityResolver},
        children: [
            {path: RoutePagesName.HOME, component: HomePageComponent, resolve: {pageParameter: PageResolver}},
            {path: RoutePagesName.PRODUCTS, component: ProductsPageComponent, resolve: {pageParameter: PageResolver}},
            {path: RoutePagesName.FAVORITE, component: FavoritePageComponent, resolve: {pageParameter: PageResolver}},
            {path: RoutePagesName.ACCOUNT, component: AccountPageComponent, resolve: {pageParameter: PageResolver}},
        ],
    },
    {
        path: `${RoutePortalsName.MEDIA_LIBRARY}/:${RouteParamName.INSTANCE_ID}`,
        component: MainPageComponent,
        canActivate: [AuthenticationGuard, NpInstanceGuard, NpEntityGuard],
        resolve: {entityParameter: EntityResolver},
        children: [
            {path: RoutePagesName.HOME, component: NpHomePageComponent, resolve: {pageParameter: PageResolver}},
            {path: RoutePagesName.MEDIAS, component: MediasPageComponent, resolve: {pageParameter: PageResolver}},
            {path: RoutePagesName.IMPORT, component: ImportMediaPageComponent, resolve: {pageParameter: PageResolver}},
            {path: RoutePagesName.FAVORITE, component: FavoritePageComponent, resolve: {pageParameter: PageResolver}},
            {path: RoutePagesName.ACCOUNT, component: AccountPageComponent, resolve: {pageParameter: PageResolver}},
        ],
    },
    {
        path: `${RoutePortalsName.SUPPLIER}/:${RouteParamName.INSTANCE_ID}`,
        component: MainPageComponent,
        canActivate: [AuthenticationGuard, NpInstanceGuard, NpEntityGuard],
        resolve: {entityParameter: EntityResolver},
        children: [
            {path: RoutePagesName.HOME, component: HomePageComponent, resolve: {pageParameter: PageResolver}},
            {path: RoutePagesName.PRODUCTS, component: ProductsPageComponent, resolve: {pageParameter: PageResolver}},
            {path: RoutePagesName.IMPORT, component: ImportPageComponent, resolve: {pageParameter: PageResolver}},
            {path: RoutePagesName.FAVORITE, component: FavoritePageComponent , resolve: {pageParameter: PageResolver}},
            {path: RoutePagesName.ACCOUNT, component: AccountPageComponent , resolve: {pageParameter: PageResolver}},
        ],
    },
    {
        path: `${RoutePortalsName.PUBLICATION}/:${RouteParamName.INSTANCE_ID}`,
        component: PublicationMainPageComponent,
        canActivate: [AuthenticationGuard, NpInstanceGuard, NpEntityGuard],
        resolve: {entityParameter: EntityResolver},
        children: [
            {path: RoutePagesName.HOME, component: PublicationHomepageComponent, resolve: {pageParameter: PageResolver}},
            {path: RoutePagesName.CATALOGUE, component: PublicationCataloguePageComponent, resolve: {pageParameter: PageResolver}},
        ],
    },
    {
        path: RoutePortalsName.NO_INSTANCE_FOUND, component: NoInstanceFoundComponent
    },
    {
        path: '', redirectTo: RoutePortalsName.NO_INSTANCE_FOUND, pathMatch: 'full'
    },
    {
        path: '**', component: NoInstanceFoundComponent
    }

];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: true
        })
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
