import {Injectable} from '@angular/core';
import {NpValueTextReadViewComponent} from '../graphics/np-value/np-value-text-read-view/np-value-text-read-view.component';
import {NpValueImageReadViewComponent} from '../graphics/np-value/np-value-image-read-view/np-value-image-read-view.component';
import {NpValueLinkReadViewComponent} from '../graphics/np-value/np-value-link-read-view/np-value-link-read-view.component';
import {NpValueTextWriteViewComponent} from '../graphics/np-value/np-value-text-write-view/np-value-text-write-view.component';
import {NpValueListReadViewComponent} from '../graphics/np-value/np-value-list-read-view/np-value-list-read-view.component';
import {NpValueListWriteViewComponent} from '../graphics/np-value/np-value-list-write-view/np-value-list-write-view.component';
import {NpValueMultiLinkBulletViewComponent} from '../graphics/np-value/np-value-multi-link-bullet-view/np-value-multi-link-bullet-view.component';
import {NpValueStatusReadViewComponent} from '../graphics/np-value/np-value-status-read-view/np-value-status-read-view.component';
import {Access, TypeCode} from '@nextpage/np-sdk-data';
import {LinkCaracWriteComponent} from '../graphics/link-carac-write-1/link-carac-write.component';
import {SelectListValueComponent} from '../graphics/select-list-value/select-list-value.component';
import {Title2Component} from '../graphics/title-2/title-2.component';
import {CaracInfo} from '../graphics/np-value/Model';
import {SelectListValueLienComponent} from '../graphics/select-list-value-lien/select-list-value-lien.component';
import {VisualMediaComponent} from '../graphics/visual-media/visual-media.component';
import {NpDatepickerComponent} from '../graphics/np-datepicker/np-datepicker.component';
import {NpSimpleImageViewComponent} from '../graphics/np-simple-image-view/np-simple-image-view.component';
import {CheckListComponent} from '../graphics/check-list/check-list.component';
import {CheckListImageComponent} from '../graphics/check-list-image/check-list-image.component';
import {NpNumberComponent} from '../graphics/np-number/np-number.component';
import {LinkStyleArrayComponent} from '../graphics/link-style-array/link-style-array.component';
import {LinkStyleArray2Component} from '../graphics/link-style-array-2/link-style-array2.component';
import {NpTreeviewComponent} from '../graphics/np-treeview/np-treeview.component';
import {LinkRouterComponent} from '../graphics/link-router/link-router.component';
import {CrossTableComponent} from '../graphics/cross-table/cross-table.component';
import {NpRepeaterComponent} from '../graphics/np-repeater/np-repeater.component';
import {NpValueLinkReaderComponent} from '../graphics/np-value-link-reader/np-value-link-reader.component';
import {NpComboboxComponent} from '../graphics/np-combobox/np-combobox.component';
import {CheckSimpleListComponent} from '../graphics/check-simple-list/check-simple-list.component';
import {NpFileUploadComponent} from '../graphics/np-file-upload/np-file-upload.component';
import {VisualMediaV2Component} from '../graphics/visual-media-v2/visual-media-v2.component';
import {LinkCaracWriterV2Component} from '../graphics/link-carac-writer-v2/link-carac-writer-v2.component';
import {NpValueHtmlWriteViewComponent} from '../graphics/np-value/np-value-html-write-view/np-value-html-write-view.component';
import {CheckSimpleListV2Component} from '../graphics/check-simple-list-v2/check-simple-list-v2.component';
import { NpMultipleChoiceComponent } from '../graphics/np-multiple-choice/np-multiple-choice.component';


// TODO extend our custom Exception class.
export class UnsupportedAttributeType {
}

enum Components {
    LINKROUTER = 'LinkRouterComponent',
    MULTILINKVIEWWITHBULLET = 'MultiLinkViewWithBullet',
    STATUSVIEW = 'StatusView',
    LINKCARACWRITER = 'LinkCaracWriteComponent',
    LINKCARACWRITER_V2 = 'LinkCaracWriterV2Component',
    SELECTLISTVALUE = 'SelectListValue',
    SELECTLISTVALUELIEN = 'SelectListValueLien',
    TITLE_2 = 'Title2Component',
    VISUALMEDIA = 'VisualMediaComponent',
    VISUALMEDIAV2 = 'VisualMediaV2Component',
    DATAPICKER = 'NpDatepickerComponent',
    SIMPLEIMAGEVIEW = 'SimpleImageView',
    CHECKLIST = 'CheckList',
    CHECKLISTIMAGE = 'CheckListImagesComponent',
    NPNUMBER = 'NpNumberComponent',
    ARRAYCOMPONENT = 'LinkStyleArrayComponent',
    ARRAYCOMPONENT2 = 'LinkStyleArray2Component',
    TREEVIEW = 'NpTreeviewComponent',
    REPEATER = 'NpRepeaterComponent',
    CROSSTABLE = 'CrossTableComponent',
    VALUELINKREADER = 'LinkReaderComponent',
    COMBOBOX = 'ComboboxComponent',
    CHECK_SIMPLE_LIST = 'CheckSimpleListComponent',
    CHECK_SIMPLE_LIST_V2 = 'CheckSimpleListV2Component',
    FILE_UPLOAD = 'FileUploadComponent',
    HTML_EDITOR = 'HTMLEditorComponent',
    MULTIPLE_CHOICE = 'NpMultipleChoiceComponent',
}

@Injectable()
export class ValueComponentResolver {
    // Composants utilisés par défaut
    private _configDict = {
        [Access.AUCUNACCESS]: {
            [TypeCode.LIENREFLEXIF]: SelectListValueLienComponent,
            [TypeCode.IMG]: NpValueImageReadViewComponent,
            [TypeCode.LIEN]: NpValueLinkReadViewComponent,
            [TypeCode.LISTE]: NpValueListReadViewComponent,
            [TypeCode.CHOIX]: NpValueListReadViewComponent,
            [TypeCode.TXT]: NpValueTextReadViewComponent,
            [TypeCode.TXTLONG]: NpValueTextReadViewComponent,
            [TypeCode.SYSLABEL]: NpValueTextReadViewComponent,
            [Components.MULTILINKVIEWWITHBULLET]: NpValueMultiLinkBulletViewComponent,
            [Components.STATUSVIEW]: NpValueStatusReadViewComponent,
            [Components.LINKCARACWRITER]: LinkCaracWriteComponent,
            [Components.LINKCARACWRITER_V2]: LinkCaracWriterV2Component,
            [Components.SELECTLISTVALUE]: SelectListValueComponent,
            [Components.TITLE_2]: Title2Component,
            [Components.VISUALMEDIA]: VisualMediaComponent,
            [Components.SIMPLEIMAGEVIEW]: NpSimpleImageViewComponent,
            [Components.DATAPICKER]: NpDatepickerComponent,
            [Components.DATAPICKER]: NpDatepickerComponent,
            [Components.NPNUMBER]: NpNumberComponent
        },
        [Access.LECTURESEULE]: {
            // [TypeCode.IMG]: NpValueImageReadViewComponent,
            [TypeCode.LIENREFLEXIF]: SelectListValueLienComponent,
            [TypeCode.IMG]: VisualMediaComponent,
            [TypeCode.LIEN]: NpValueLinkReadViewComponent,
            [TypeCode.LISTE]: NpValueListReadViewComponent,
            [TypeCode.CHOIX]: NpValueListReadViewComponent,
            [TypeCode.TXT]: NpValueTextReadViewComponent,
            [TypeCode.TXTLONG]: NpValueTextReadViewComponent,
            [TypeCode.SYSLABEL]: NpValueTextReadViewComponent,
            [Components.MULTILINKVIEWWITHBULLET]: NpValueMultiLinkBulletViewComponent,
            [Components.STATUSVIEW]: NpValueStatusReadViewComponent,
            [Components.LINKCARACWRITER]: LinkCaracWriteComponent,
            [Components.LINKCARACWRITER_V2]: LinkCaracWriterV2Component,
            [Components.SELECTLISTVALUE]: SelectListValueComponent,
            [Components.TITLE_2]: Title2Component,
            [Components.VISUALMEDIA]: VisualMediaComponent,
            [Components.SIMPLEIMAGEVIEW]: NpSimpleImageViewComponent,
            [Components.CHECKLIST]: CheckListComponent,
            [Components.DATAPICKER]: NpDatepickerComponent,
            [Components.CHECKLISTIMAGE]: CheckListImageComponent,
            [Components.DATAPICKER]: NpDatepickerComponent,
            [Components.NPNUMBER]: NpNumberComponent,
            [TypeCode.STRUCTUREDDATA]: LinkStyleArrayComponent,
            [TypeCode.TXTHTML_HTML]: NpValueHtmlWriteViewComponent
        },
        [Access.REDACTEUR]: {
            // [TypeCode.IMG]: NpValueImageWriteViewComponent,
            [TypeCode.LIENREFLEXIF]: SelectListValueLienComponent,
            [TypeCode.IMG]: VisualMediaComponent,
            [TypeCode.LIEN]: NpValueLinkReadViewComponent,
            [TypeCode.LISTE]: NpValueListWriteViewComponent,
            [TypeCode.CHOIX]: NpValueListWriteViewComponent,
            [TypeCode.TXT]: NpValueTextWriteViewComponent,
            [TypeCode.TXTLONG]: NpValueTextWriteViewComponent,
            [TypeCode.SYSLABEL]: NpValueTextWriteViewComponent,
            [Components.MULTILINKVIEWWITHBULLET]: NpValueMultiLinkBulletViewComponent,
            [Components.STATUSVIEW]: NpValueStatusReadViewComponent,
            [Components.LINKCARACWRITER]: LinkCaracWriteComponent,
            [Components.LINKCARACWRITER_V2]: LinkCaracWriterV2Component,
            [Components.SELECTLISTVALUE]: SelectListValueComponent,
            [Components.TITLE_2]: Title2Component,
            [Components.VISUALMEDIA]: VisualMediaComponent,
            [Components.DATAPICKER]: NpDatepickerComponent,
            [Components.SIMPLEIMAGEVIEW]: NpSimpleImageViewComponent,
            [Components.CHECKLIST]: CheckListComponent,
            [Components.CHECKLISTIMAGE]: CheckListImageComponent,
            [Components.NPNUMBER]: NpNumberComponent,
            [TypeCode.STRUCTUREDDATA]: LinkStyleArrayComponent,
            [TypeCode.TXTHTML_HTML]: NpValueHtmlWriteViewComponent
        },
        [Access.GESTIONNAIRE]: {
            // [TypeCode.IMG]: NpValueImageWriteViewComponent,
            [TypeCode.LIENREFLEXIF]: SelectListValueLienComponent,
            [TypeCode.IMG]: VisualMediaComponent,
            [TypeCode.LIEN]: NpValueLinkReadViewComponent,
            [TypeCode.LISTE]: NpValueListWriteViewComponent,
            [TypeCode.CHOIX]: NpValueListWriteViewComponent,
            [TypeCode.TXT]: NpValueTextWriteViewComponent,
            [TypeCode.TXTLONG]: NpValueTextWriteViewComponent,
            [TypeCode.SYSLABEL]: NpValueTextWriteViewComponent,
            [Components.MULTILINKVIEWWITHBULLET]: NpValueMultiLinkBulletViewComponent,
            [Components.STATUSVIEW]: NpValueStatusReadViewComponent,
            [Components.LINKCARACWRITER]: LinkCaracWriteComponent,
            [Components.LINKCARACWRITER_V2]: LinkCaracWriterV2Component,
            [Components.SELECTLISTVALUE]: SelectListValueComponent,
            [Components.TITLE_2]: Title2Component,
            [Components.DATAPICKER]: NpDatepickerComponent,
            [Components.VISUALMEDIA]: VisualMediaComponent,
            [Components.SIMPLEIMAGEVIEW]: NpSimpleImageViewComponent,
            [Components.CHECKLIST]: CheckListComponent,
            [Components.CHECKLISTIMAGE]: CheckListImageComponent,
            [Components.NPNUMBER]: NpNumberComponent,
            [TypeCode.STRUCTUREDDATA]: LinkStyleArrayComponent,
            [TypeCode.TXTHTML_HTML]: NpValueHtmlWriteViewComponent
        }
    };

    private _mapComponents = {
        [Access.AUCUNACCESS]: {
            [Components.SELECTLISTVALUE]: SelectListValueComponent,
            [Components.TITLE_2]: Title2Component,
            [Components.SELECTLISTVALUELIEN]: SelectListValueLienComponent,
            [Components.VISUALMEDIA]: VisualMediaComponent,
            [Components.DATAPICKER]: NpDatepickerComponent,
            [Components.SIMPLEIMAGEVIEW]: NpSimpleImageViewComponent,
            [Components.CHECKLIST]: CheckListComponent,
            [Components.CHECKLISTIMAGE]: CheckListImageComponent,
            [Components.LINKCARACWRITER]: LinkCaracWriteComponent,
            [Components.NPNUMBER]: NpNumberComponent,
            [Components.ARRAYCOMPONENT]: LinkStyleArrayComponent,
            [Components.ARRAYCOMPONENT2]: LinkStyleArray2Component,
            [Components.TREEVIEW]: NpTreeviewComponent,
            [Components.REPEATER]: NpRepeaterComponent,
            [Components.LINKROUTER]: LinkRouterComponent,
            [Components.CROSSTABLE]: CrossTableComponent,
            [Components.COMBOBOX]: NpComboboxComponent,
            [Components.CHECK_SIMPLE_LIST]: CheckSimpleListComponent,
            [Components.CHECK_SIMPLE_LIST_V2]: CheckSimpleListV2Component,
            [Components.FILE_UPLOAD]: NpFileUploadComponent,
            [Components.VISUALMEDIAV2]: VisualMediaV2Component,
        },
        [Access.LECTURESEULE]: {
            [Components.SELECTLISTVALUE]: SelectListValueComponent,
            [Components.TITLE_2]: Title2Component,
            [Components.SELECTLISTVALUELIEN]: SelectListValueLienComponent,
            [Components.VISUALMEDIA]: VisualMediaComponent,
            [Components.SIMPLEIMAGEVIEW]: NpSimpleImageViewComponent,
            [Components.CHECKLIST]: CheckListComponent,
            [Components.DATAPICKER]: NpDatepickerComponent,
            [Components.CHECKLISTIMAGE]: CheckListImageComponent,
            [Components.LINKCARACWRITER]: LinkCaracWriteComponent,
            [Components.LINKCARACWRITER_V2]: LinkCaracWriterV2Component,
            [Components.DATAPICKER]: NpDatepickerComponent,
            [Components.NPNUMBER]: NpNumberComponent,
            [Components.ARRAYCOMPONENT]: LinkStyleArrayComponent,
            [Components.ARRAYCOMPONENT2]: LinkStyleArray2Component,
            [Components.TREEVIEW]: NpTreeviewComponent,
            [Components.REPEATER]: NpRepeaterComponent,
            [Components.LINKROUTER]: LinkRouterComponent,
            [Components.VALUELINKREADER]: NpValueLinkReaderComponent,
            [Components.CROSSTABLE]: CrossTableComponent,
            [Components.COMBOBOX]: NpComboboxComponent,
            [Components.CHECK_SIMPLE_LIST]: CheckSimpleListComponent,
            [Components.CHECK_SIMPLE_LIST_V2]: CheckSimpleListV2Component,
            [Components.FILE_UPLOAD]: NpFileUploadComponent,
            [Components.VISUALMEDIAV2]: VisualMediaV2Component,
            [Components.HTML_EDITOR]: NpValueHtmlWriteViewComponent
        },
        [Access.REDACTEUR]: {
            [Components.SELECTLISTVALUE]: SelectListValueComponent,
            [Components.TITLE_2]: Title2Component,
            [Components.SELECTLISTVALUELIEN]: SelectListValueLienComponent,
            [Components.VISUALMEDIA]: VisualMediaComponent,
            [Components.DATAPICKER]: NpDatepickerComponent,
            [Components.SIMPLEIMAGEVIEW]: NpSimpleImageViewComponent,
            [Components.CHECKLIST]: CheckListComponent,
            [Components.CHECKLISTIMAGE]: CheckListImageComponent,
            [Components.LINKCARACWRITER]: LinkCaracWriteComponent,
            [Components.LINKCARACWRITER_V2]: LinkCaracWriterV2Component,
            [Components.NPNUMBER]: NpNumberComponent,
            [Components.ARRAYCOMPONENT]: LinkStyleArrayComponent,
            [Components.ARRAYCOMPONENT2]: LinkStyleArray2Component,
            [Components.TREEVIEW]: NpTreeviewComponent,
            [Components.REPEATER]: NpRepeaterComponent,
            [Components.LINKROUTER]: LinkRouterComponent,
            [Components.CROSSTABLE]: CrossTableComponent,
            [Components.COMBOBOX]: NpComboboxComponent,
            [Components.CHECK_SIMPLE_LIST]: CheckSimpleListComponent,
            [Components.CHECK_SIMPLE_LIST_V2]: CheckSimpleListV2Component,
            [Components.FILE_UPLOAD]: NpFileUploadComponent,
            [Components.VISUALMEDIAV2]: VisualMediaV2Component,
            [Components.HTML_EDITOR]: NpValueHtmlWriteViewComponent,
            [Components.MULTIPLE_CHOICE]: NpMultipleChoiceComponent,
        },
        [Access.GESTIONNAIRE]: {
            [Components.SELECTLISTVALUE]: SelectListValueComponent,
            [Components.TITLE_2]: Title2Component,
            [Components.SELECTLISTVALUELIEN]: SelectListValueLienComponent,
            [Components.VISUALMEDIA]: VisualMediaComponent,
            [Components.SIMPLEIMAGEVIEW]: NpSimpleImageViewComponent,
            [Components.CHECKLIST]: CheckListComponent,
            [Components.DATAPICKER]: NpDatepickerComponent,
            [Components.CHECKLISTIMAGE]: CheckListImageComponent,
            [Components.LINKCARACWRITER]: LinkCaracWriteComponent,
            [Components.LINKCARACWRITER_V2]: LinkCaracWriterV2Component,
            [Components.DATAPICKER]: NpDatepickerComponent,
            [Components.NPNUMBER]: NpNumberComponent,
            [Components.ARRAYCOMPONENT]: LinkStyleArrayComponent,
            [Components.ARRAYCOMPONENT2]: LinkStyleArray2Component,
            [Components.TREEVIEW]: NpTreeviewComponent,
            [Components.REPEATER]: NpRepeaterComponent,
            [Components.LINKROUTER]: LinkRouterComponent,
            [Components.CROSSTABLE]: CrossTableComponent,
            [Components.COMBOBOX]: NpComboboxComponent,
            [Components.CHECK_SIMPLE_LIST]: CheckSimpleListComponent,
            [Components.CHECK_SIMPLE_LIST_V2]: CheckSimpleListV2Component,
            [Components.FILE_UPLOAD]: NpFileUploadComponent,
            [Components.VISUALMEDIAV2]: VisualMediaV2Component,
            [Components.HTML_EDITOR]: NpValueHtmlWriteViewComponent,
            [Components.MULTIPLE_CHOICE]: NpMultipleChoiceComponent,
        }
    };

    getComponentClass(caracInfo: CaracInfo) {
        if (caracInfo == null) {
            throw new UnsupportedAttributeType();
        }

        if (caracInfo.componentRouting != null) {
            if (caracInfo.componentRouting[Access.ALL] != null) {
                return caracInfo.componentRouting[Access.ALL];
            }
            if (caracInfo.componentRouting[caracInfo.authorization] != null) {
                return caracInfo.componentRouting[caracInfo.authorization];
            }
        }


        let componentClass = this.getComponent(this._configDict, caracInfo.type, caracInfo.authorization);
        if (caracInfo.component !== '' && caracInfo.component != null) {
            componentClass = this.getComponent(this._mapComponents, caracInfo.component, caracInfo.authorization);
        }
        if (componentClass == null) {
            throw new UnsupportedAttributeType();
        }
        return componentClass;
    }

    /**
     * retourne le bon composant à partir de la configuration
     * mapping : indique le dictionnaire de configuration a utiliser
     * key : clé utilisée pour la configuration
     * auth : niveau d'autorisation
     */
    private getComponent(mapping, key, auth) {
        const componentClassByAuthorization = mapping[auth];
        if (componentClassByAuthorization == null) {
            return null;
        }
        // retourner une erreur si la configuration est inexistante
        const componentClass = componentClassByAuthorization[key];
        if (componentClass == null) {
            throw new UnsupportedAttributeType();
        }

        return componentClass;
    }

    /*
    getComponentClass(caracInfo: CaracInfo) {
        if (caracInfo == null) {
            throw new UnsupportedAttributeType();
        }

        if (caracInfo.componentRouting != null) {
            if (caracInfo.componentRouting[Access.ALL] != null) {
                return caracInfo.componentRouting[Access.ALL];
            }
            if (caracInfo.componentRouting[caracInfo.authorization] != null) {
                return caracInfo.componentRouting[caracInfo.authorization];
            }
        }
        const componentClassByAuthorization = this._configDict[caracInfo.authorization];
        if (componentClassByAuthorization == null) {
            return null;
        }

        const key: any = caracInfo.type;


        const componentClass = componentClassByAuthorization[key];
        if (componentClass == null) {
            throw new UnsupportedAttributeType();
        }

        return componentClass;

    }
    */

}
