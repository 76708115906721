import {NpInstanceConfiguration} from '../models/np-instance-model';
import {NPElementType} from '@lib/models';
import {EntityName} from '../../../lib/data/model/portal';
import {RoutePortalsName} from './route-portals.constants';

export const NP_INSTANCE_CONFIG: Map<string, NpInstanceConfiguration> = new Map([

    ['demo-onduline', {
        instanceId: 'demo-onduline',
        name: 'demo onduline',
        npBaseUrl: 'https://demo-onduline.nextpage.fr/',
        defaultEntityRoute: RoutePortalsName.CONSULTATION,
        elementType: NPElementType.Product,
        bgImageName: 'login-background-onduline.webp',
        portalType: EntityName.Consultation,
        styleClass: '',
        logoName: 'onduline-logo.svg',
        loginLogoName: 'onduline-logo.svg',
        allowedEntities: [EntityName.Consultation, EntityName.MediaLibrary, EntityName.Supplier],
        title: 'Portail - Onduline',
        favicon: 'favicon-onduline.ico',
        emptyImage: '',
        publicationDefaultImageTemplate: ''
    }],
    ['traxion', {
        instanceId: 'traxion',
        name: 'Traxion',
        npBaseUrl: 'https://preprod-traxion.nextpage.fr/',
        defaultEntityRoute: RoutePortalsName.MEDIA_LIBRARY,
        elementType: NPElementType.Media,
        bgImageName: 'login-background-traxion.jpg',
        portalType: EntityName.MediaLibrary,
        styleClass: '',
        logoName: 'traxion-logo.svg',
        loginLogoName: 'traxion-logo.svg',
        allowedEntities: [EntityName.MediaLibrary],
        title: 'Portail - Traxion',
        favicon: 'favicon-traxion.png',
        emptyImage: '',
        publicationDefaultImageTemplate: ''
    }],
    ['demo-sauermann', {
        instanceId: 'demo-sauermann',
        name: 'demo sauermann',
        npBaseUrl: 'https://demo-sauermann.nextpage.fr/',
        defaultEntityRoute: RoutePortalsName.CONSULTATION,
        elementType: NPElementType.Product,
        bgImageName: 'sauermann-background.jpg',
        portalType: EntityName.Consultation,
        styleClass: '',
        logoName: 'sauermann-logo.png',
        loginLogoName: 'sauermann-logo.png',
        allowedEntities: [EntityName.Consultation, EntityName.MediaLibrary, EntityName.Supplier],
        title: 'Sauermann',
        favicon: 'favicon-sauermann.png',
        emptyImage: '',
        publicationDefaultImageTemplate: ''
    }],
    ['projet', {
        instanceId: 'projet',
        name: 'Projet',
        npBaseUrl: 'https://projet-portail.nextpage.fr/',
        defaultEntityRoute: RoutePortalsName.CONSULTATION,
        elementType: NPElementType.Product,
        bgImageName: 'login-background-fermob.jpg',
        portalType: EntityName.Consultation,
        styleClass: '',
        logoName: 'default-logo.png',
        loginLogoName: 'default-logo.png',
        allowedEntities: [EntityName.Consultation, EntityName.MediaLibrary, EntityName.Supplier, EntityName.Publication],
        title: 'projet',
        favicon: '',
        emptyImage: 'missing-image-sportpulsion.png',
        publicationDefaultImageTemplate: 'missing-template-image.png'
    }],
    ['projet-references', {
        instanceId: 'projetReferences',
        name: 'Projet Références',
        npBaseUrl: 'https://projet-portail.nextpage.fr/',
        defaultEntityRoute: RoutePortalsName.CONSULTATION,
        elementType: NPElementType.Reference,
        bgImageName: 'login-background-fermob.jpg',
        portalType: EntityName.Consultation,
        styleClass: '',
        logoName: 'default-logo.png',
        loginLogoName: 'default-logo.png',
        allowedEntities: [EntityName.Consultation, EntityName.MediaLibrary, EntityName.Supplier],
        title: 'projet références',
        favicon: '',
        emptyImage: '',
        publicationDefaultImageTemplate: 'missing-template-image.png'
    }],
    ['fermob', {
        instanceId: 'fermob',
        name: 'Fermob',
        npBaseUrl: 'https://projet-fermob375.nextpage.fr/',
        defaultEntityRoute: RoutePortalsName.CONSULTATION,
        elementType: NPElementType.Product,
        bgImageName: 'login-background-fermob.jpg',
        portalType: EntityName.Consultation,
        styleClass: '',
        logoName: 'default-logo.png',
        loginLogoName: 'default-logo.png',
        allowedEntities: [EntityName.Consultation, EntityName.MediaLibrary, EntityName.Supplier],
        title: 'Fermob',
        favicon: '',
        emptyImage: '',
        publicationDefaultImageTemplate: ''
    }],
    ['ctop', {
        instanceId: 'ctop',
        name: 'Ctop',
        npBaseUrl: 'https://preprod-ctop.nextpage.fr/',
        defaultEntityRoute: RoutePortalsName.CONSULTATION,
        elementType: NPElementType.Product,
        bgImageName: 'login-background-ctop.png',
        styleClass: '',
        logoName: 'default-logo.png',
        loginLogoName: 'default-logo.png',
        allowedEntities: [EntityName.Consultation],
        title: 'Ctop',
        favicon: '',
        emptyImage: '',
        publicationDefaultImageTemplate: ''
    }],
    ['caib', {
        instanceId: 'caib',
        name: 'Caib',
        npBaseUrl: 'https://preprod-caib.nextpage.fr/',
        defaultEntityRoute: RoutePortalsName.CONSULTATION,
        elementType: NPElementType.Reference,
        bgImageName: '',
        styleClass: '',
        logoName: 'default-logo.png',
        loginLogoName: 'default-logo.png',
        allowedEntities: [EntityName.Consultation, EntityName.MediaLibrary, EntityName.Supplier],
        title: 'Caib',
        favicon: '',
        emptyImage: '',
        publicationDefaultImageTemplate: ''
    }],
    ['boschatlaveix', {
        instanceId: 'boschatlaveix',
        name: 'Boschatlaveix',
        npBaseUrl: 'https://demo-boschatlaveix.nextpage.fr/',
        defaultEntityRoute: RoutePortalsName.CONSULTATION,
        elementType: NPElementType.Reference,
        bgImageName: 'login-background-boschatlaveix.png',
        styleClass: '',
        logoName: 'default-logo.png',
        loginLogoName: 'default-logo.png',
        allowedEntities: [EntityName.Consultation, EntityName.MediaLibrary, EntityName.Supplier],
        title: 'Boschatlaveix',
        favicon: '',
        emptyImage: '',
        publicationDefaultImageTemplate: ''
    }],
    ['sportpulsion', {
        instanceId: 'sportpulsion',
        name: 'Sportpulsion',
        npBaseUrl: 'https://preprod-sportpulsion.nextpage.fr/',
        defaultEntityRoute: RoutePortalsName.CONSULTATION,
        elementType: NPElementType.Product,
        bgImageName: 'login-background-sp.png',
        styleClass: '',
        logoName: 'sport-pulsion-logo.png',
        loginLogoName: 'sport-pulsion-logo.png',
        allowedEntities: [EntityName.Consultation, EntityName.MediaLibrary],
        title: 'Portail – Sportpulsion',
        favicon: 'favicon-sportpulsion.png',
        emptyImage: 'missing-image-sportpulsion.png',
        publicationDefaultImageTemplate: ''
    }],
    ['gravotech', {
        instanceId: 'gravotech',
        name: 'Gravotech',
        npBaseUrl: 'https://preprod-gravotech.nextpage.fr/',
        defaultEntityRoute: RoutePortalsName.MEDIA_LIBRARY,
        elementType: NPElementType.Reference,
        bgImageName: '',
        styleClass: '',
        logoName: 'default-logo.png',
        loginLogoName: 'default-logo.png',
        allowedEntities: [EntityName.MediaLibrary],
        title: 'Gravotech',
        favicon: '',
        emptyImage: '',
        publicationDefaultImageTemplate: ''
    }],
    ['demo-peugeotsaveurs', {
        instanceId: 'peugeotsaveurs',
        name: 'Peugeot saveurs',
        npBaseUrl: 'https://demo-peugeotsaveurs.nextpage.fr/',
        defaultEntityRoute: RoutePortalsName.CONSULTATION,
        elementType: NPElementType.Product,
        bgImageName: 'login-background-peugeotsaveurs.jpg',
        styleClass: 'peugeot-saveurs',
        logoName: 'peugeot-saveurs.png',
        loginLogoName: 'peugeot-saveurs.png',
        allowedEntities: [EntityName.Consultation, EntityName.MediaLibrary],
        title: 'Peugeot saveurs',
        favicon: '',
        emptyImage: '',
        publicationDefaultImageTemplate: ''
    }],
    ['projet-peugeotsaveurs', {
        instanceId: 'projet-peugeotsaveurs',
        name: 'Peugeot saveurs',
        npBaseUrl: 'https://projet-peugeotsaveurs.nextpage.fr/',
        defaultEntityRoute: RoutePortalsName.CONSULTATION,
        elementType: NPElementType.Product,
        bgImageName: 'login-background-peugeotsaveurs.jpg',
        styleClass: 'peugeot-saveurs',
        logoName: 'peugeot-saveurs.png',
        loginLogoName: 'peugeot-saveurs.png',
        allowedEntities: [EntityName.Consultation, EntityName.MediaLibrary],
        title: 'Peugeot saveurs',
        favicon: '',
        emptyImage: '',
        publicationDefaultImageTemplate: ''
    }],
    ['demo-serge-ferrari', {
        instanceId: 'demo-serge-ferrari',
        name: 'Serge Ferrari',
        npBaseUrl: 'https://demo-sergeferrari.nextpage.fr/',
        defaultEntityRoute: RoutePortalsName.CONSULTATION,
        elementType: NPElementType.Product,
        bgImageName: '',
        styleClass: '',
        logoName: 'default-logo.png',
        loginLogoName: 'default-logo.png',
        allowedEntities: [EntityName.Consultation],
        title: 'Serge Ferrari',
        favicon: '',
        emptyImage: '',
        publicationDefaultImageTemplate: ''
    }],
    ['testa375', {
        instanceId: 'testa375',
        name: 'Testa375',
        npBaseUrl: 'https://projet-testa375.nextpage.fr/',
        defaultEntityRoute: RoutePortalsName.CONSULTATION,
        elementType: NPElementType.Product,
        bgImageName: 'testa375.jpg',
        styleClass: '',
        logoName: 'default-logo.png',
        loginLogoName: 'default-logo.png',
        allowedEntities: [EntityName.Consultation, EntityName.MediaLibrary, EntityName.Supplier],
        title: 'testa375',
        favicon: '',
        emptyImage: '',
        publicationDefaultImageTemplate: ''
    }],
    ['local', {
        instanceId: 'local',
        name: 'local',
        npBaseUrl: 'https://localhost:44303/',
        defaultEntityRoute: RoutePortalsName.CONSULTATION,
        elementType: NPElementType.Product,
        bgImageName: 'testa375.jpg',
        styleClass: '',
        logoName: 'default-logo.png',
        loginLogoName: 'default-logo.png',
        allowedEntities: [EntityName.Consultation, EntityName.MediaLibrary, EntityName.Supplier, EntityName.Publication],
        title: 'local',
        favicon: '',
        emptyImage: '',
        publicationDefaultImageTemplate: 'missing-template-image.png'
    }],
    ['demo-winoa', {
        instanceId: 'demo-winoa',
        name: 'demo winoa',
        npBaseUrl: 'https://demo-winoa.nextpage.fr/',
        defaultEntityRoute: RoutePortalsName.CONSULTATION,
        elementType: NPElementType.Product,
        bgImageName: 'login-background-winoa.png',
        styleClass: 'winoa',
        logoName: 'winoa-logo.svg',
        loginLogoName: 'winoa-logo.png',
        allowedEntities: [EntityName.Consultation],
        title: 'demo-winoa',
        favicon: '',
        emptyImage: '',
        publicationDefaultImageTemplate: ''
    }],
    ['batiman', {
        instanceId: 'batiman',
        name: 'Batiman',
        npBaseUrl: 'https://preprod-batiman.nextpage.fr/',
        defaultEntityRoute: RoutePortalsName.PUBLICATION,
        elementType: NPElementType.Product,
        bgImageName: 'login-background-batiman.jpg',
        portalType: EntityName.Publication,
        styleClass: '',
        logoName: 'batiman-logo.svg',
        loginLogoName: 'batiman-logo.svg',
        allowedEntities: [EntityName.Publication],
        title: 'batiman',
        favicon: 'favicon-batiman.svg',
        emptyImage: '',
        publicationDefaultImageTemplate: 'missing-template-image.png'
    }],
    ['projetpublication', {
        instanceId: 'projetpublication',
        name: 'Projet publication',
        npBaseUrl: 'https://projet-portailpublication.nextpage.fr/',
        defaultEntityRoute: RoutePortalsName.PUBLICATION,
        elementType: NPElementType.Product,
        bgImageName: 'login-background-batiman.jpg',
        portalType: EntityName.Publication,
        styleClass: '',
        logoName: 'batiman-logo.svg',
        loginLogoName: 'batiman-logo.svg',
        allowedEntities: [EntityName.Publication],
        title: 'batiman',
        favicon: 'favicon-batiman.svg',
        emptyImage: 'missing-template-image.png',
        publicationDefaultImageTemplate: 'missing-template-image.png'
    }],
    ['demo-upsa', {
        instanceId: 'demo-upsa',
        name: 'UPSA',
        npBaseUrl: 'https://demo-upsa.nextpage.fr/',
        defaultEntityRoute: RoutePortalsName.MEDIA_LIBRARY,
        elementType: NPElementType.Product,
        bgImageName: '',
        styleClass: 'upsa',
        logoName: 'upsa-logo.png',
        loginLogoName: 'upsa-logo.png',
        allowedEntities: [EntityName.MediaLibrary],
        title: 'UPSA Frabriqué en France',
        favicon: '',
        emptyImage: '',
        publicationDefaultImageTemplate: ''
    }],
]);
