<div class='row'>
    <div>
        <div class="d-flex align-items-center justify-content-between">
            <h3 class="m-0 page-title">{{ 'medias.title' | translate }}</h3>
            <form>
                <div class='mat-form-field-text'>
                    <div>
                        <mat-form-field appearance='outline' class="field-search-custom">
                            <mat-label>{{ 'common.search' | translate }}</mat-label>
                            <input [formControl]="searchFormControl"
                                   type='text'
                                   matInput
                                   (keyup.enter)='searchByKeyword($event.target.value)'
                                   #searchFilter/>
                            <img matSuffix src="./assets/icons/search.svg" class="fontawesome-icon-custom" alt="search"/>
                        </mat-form-field>
                        <img src="./assets/icons/x-mark.svg"
                             class="fontawesome-icon-custom ml-2 cursor-pointer"
                             alt="Reset"
                             *ngIf="searchFilter.value !== ''"
                             (click)="resetSearch($event)"/>
                    </div>
                </div>
            </form>
        </div>
        <mat-divider class='my-3'></mat-divider>
        

        <div class="d-flex justify-content-between align-items-center list-filters">
            <app-char-template-filters
                    [charTemplates]="charTemplatesView$ | async"
                    [advancedSearch]="advancedSearch"
                    [selectedDataLanguageId]="selectedDataLanguageID"
                    [defaultItems]="defaultAdvancedSearch?.Config?.Filters?.Chars?.Items || []"
                    (resetAllFilters)="resetMediaFilters()"
                    (filterElements)="searchMedias()"
                    (filterByProductType)="filterByProductType($event.productType)">
            </app-char-template-filters>

            <mat-button-toggle-group class="toggle-views" [value]="toggle" (change)="toggleView($event)">
                <mat-button-toggle [value]="true">
                    <mat-icon>menu</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle [value]="false">
                    <mat-icon>apps</mat-icon>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>

        <div [ngClass]="{'mat-elevation-z2': toggle}">
            <ng-container *ngIf="mediaView$ | async as view">
                <ng-container *ngIf="view.loading else resultBlock">
                    <app-loader-spinner></app-loader-spinner>
                </ng-container>
                <ng-template #resultBlock>
                    <ng-container *ngIf="view.hasError else dataBlock">
                        <div>{{ 'common.error-loading-data' | translate }}</div>
                    </ng-container>
                    <ng-template #dataBlock>
                        <div class="table-container" *ngIf="toggle">
                            <app-media-table
                                    [dataSource]='view?.data?.mediaSummary'
                                    [columnsToDisplay]='columnsToDisplay'
                                    [headerLabels]='headerLabels'>
                            </app-media-table>
                        </div>

                        <div class="cards-block-content mb-2" *ngIf="!toggle">
                            <app-media-card [filteredCard]="view?.data?.cards || []"></app-media-card>
                        </div>

                        <mat-paginator [ngClass]="{'no-background': !toggle}"
                                        [length]='view?.data?.count'
                                        showFirstLastButtons
                                        [pageSize]='pagination.pageSize'
                                        [pageIndex]="pagination.pageIndex"
                                        [pageSizeOptions]='[5, 10, 25]'
                                        (page)='onPageChange($event)'>
                        </mat-paginator>
                    </ng-template>
                </ng-template>
            </ng-container>
        </div>
    </div>
</div>
