import {ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Subscription} from 'rxjs';
import { CaracConfig, NPCaracListe, NPListeValues } from 'src/lib/data/model';
import { ElementWriterService } from 'src/lib/data/services';
import { CaracInfo } from '../np-value/Model';

@Component({
  selector: 'app-np-multiple-choice',
  templateUrl: './np-multiple-choice.component.html',
  styleUrls: ['./np-multiple-choice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NpMultipleChoiceComponent implements OnInit, OnChanges, OnDestroy {

  @Input() caracInfo: CaracInfo;
  @Input() value: NPCaracListe;
  @Input() caracConfig: CaracConfig;
  @Input() advancedMode: boolean;

  public displayValues: NPListeValues[];
  public selectedItems = new FormControl<NPListeValues[] | null>([]);

  private _sub: Subscription;

  constructor(private _elementWriterService: ElementWriterService) {
  }

  ngOnInit() {
    this.initValues();
    this._valueChanges();
  }

  ngOnChanges() {
    this.initValues();
  }

  public initValues() {
    this.displayValues = this.caracConfig && this.caracConfig.DicoCarac ? this.caracConfig.DicoCarac.Values : [];
    this._initSelectedItems();
  }

  private _initSelectedItems() {
    const selectedItems = this.value.Element.getValueListe(this.caracConfig.DicoCaracExtID)?.Values
      ?.map(item => this.displayValues.find(displayValue => displayValue.ValeurID === item.ValueID)) || [];
    this.selectedItems.reset(selectedItems);
  }

  private _valueChanges() {
    this._sub = this.selectedItems
      .valueChanges
      .subscribe((values: NPListeValues[]) => {
        this._elementWriterService.setValueListeByIDValues(
          this.value.Element,
          this.caracConfig.DicoCaracExtID,
          values?.map(value => value.ValeurID) || []
        );
      });
  }

  ngOnDestroy(): void {
    this._sub?.unsubscribe();
  }
}
