import {Component, Input, OnInit} from '@angular/core';
import {AdvSearchArgs, NPCaracLien, NPCaracValeur} from '@lib/models';
import {UploadImage} from '../../../../lib/graphics/np-media-library/np-media-library.component';
import {FileExtension} from '../../../data/helpers/file-helper';
import {Store} from '@ngrx/store';
import {createMediaByExternalUrl, StartSaving, UploadFiles} from '@store/action';
import {selectIsSaving} from '../../../store/selector/media-library.selector';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-import-media-page',
    templateUrl: './import-media-page.component.html',
    styleUrls: ['./import-media-page.component.scss'],
})

export class ImportMediaPageComponent implements OnInit {

    @Input() value: NPCaracLien;
    public currentMedia: NPCaracValeur;
    public filesMap = new Map<string, File>();
    public filesList: File[] = [];
    public uploadImages: UploadImage[] = [];
    public dropZoneHovered = false;
    public fileName: string;
    public selectedFile: File;
    public fileError = false;
    public advancedSearch: AdvSearchArgs;
    public urlMedia: string;
    public isSaving$: Observable<boolean>;



    constructor(
        private _store: Store) {

    }

    ngOnInit() {
        this.isSaving$ = this._store.select(selectIsSaving);
    }

    public onFileChange(event) {
        const files = event.target.files;
        if (files.length) {
            for (let i = 0; i < files.length; i++) {
                this.handleDrop(files[i]);
            }
        }
    }

    handleHover($event: boolean) {
        this.dropZoneHovered = $event;
    }

    public handleDrop(file: File) {

        const files = Array.isArray(file) ? file : [file];
        files.forEach(() => {
            if (file && !this.filesMap.has(file.name) && FileExtension.isImage(file)) {
                this.fileError = false;
                this.filesMap.set(file.name, file);
                this.filesList.push(file);
                this.uploadImages.push(new UploadImage(file));
            }
        });
    }

    uploadAllFiles() {
        this._store.dispatch(UploadFiles({ uploadImages: this.uploadImages }));
        this._store.dispatch(StartSaving());
    }

    removeFile(uploadImage: UploadImage, event?: Event) {
        if (event) {
            event.stopPropagation();
        }
        this.uploadImages = this.uploadImages.filter(currentFile => currentFile.file.name !== uploadImage.file.name);
        this.filesMap.delete(uploadImage.file.name);
        this.filesList = this.filesList.filter(file => file.name !== uploadImage.file.name);
        if (this.selectedFile && this.selectedFile.name === uploadImage.file.name) {
            this.selectedFile = null;
            this.fileName = '';
        }
    }

    createMediaByExternalUrl() {
        this._store.dispatch(createMediaByExternalUrl({url: this.urlMedia}));
    }
}
