import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {ProductSummary} from '../models';
import {AdvSearchArgs, DicoCarac, FileReaderService, NPElement, NPElementType, SearchRepository} from '../../../lib';
import {map, switchMap} from 'rxjs/operators';
import {ParamsFilterBuilder} from '@data/builders';
import {ElementLabels} from '@data/constants';
import {SpUserInfoService, TableHandlerService} from '@data/services';
import {ThumbsService} from '@data/services';
import {MediaSearchResult} from '../models/advanced-search-result.model';
import {MatTableDataSource} from '@angular/material/table';
import {selectThumbParameters} from '../../store/file';
import {Store} from '@ngrx/store';
import {getExtensionFromFile} from '@np/utils';

@Injectable({
    providedIn: 'root'
})
export class MediaFacade {

    private paramFilterBuilder: ParamsFilterBuilder;

    public npElementToCards(element: NPElement): Partial<ProductSummary> {
        const label = element.getValueTextValue(ElementLabels[element.ElementType]);
        return new ProductSummary({
            label: label,
            modificationDate: TableHandlerService.toLocalDate(TableHandlerService.getLastModifiedInfos(element)),
            action: '', // Permet de créer la colonne "Actions" dans le tableau pour afficher les boutons
            element: element,
            imageUrl: '',
            imageName: element.getValueTextValue(DicoCarac.MEDIA_FILE),
            fileExtension: getExtensionFromFile(label),
        });
    }

    constructor(
        private _searchRepository: SearchRepository,
        private _fileReaderService: FileReaderService,
        private _thumbsSizeService: ThumbsService,
        private _userInfoService: SpUserInfoService,
        private _store: Store) {
        this.paramFilterBuilder = new ParamsFilterBuilder().withNumberOfElementByPage(10).withElementTypes([NPElementType.Media]);
    }

    public getImageUrl(image: string): string {
        return this._fileReaderService.toFilePath(image);
    }

    public getFilePathToDownload(image: string): string {
        return this._fileReaderService.toFilePathToDownload(image);
    }

    public getFilePathToDownloadCustom(image: string, size: string) {
        return this._fileReaderService.toFilePathToDownloadCustomSize(image, size);
    }

    search(advancedSearch: AdvSearchArgs, multiOperators: boolean): Observable<MediaSearchResult> {
        const searchRoute = multiOperators ? this._searchRepository.SearchWithMultipleOperators(advancedSearch) : this._searchRepository.Search(advancedSearch);
        return advancedSearch ?
            searchRoute
                .pipe(
                    map(result => {
                        return {
                            cards: result?.elements?.map(this.npElementToCards) || [],
                            count: result.resultsCount
                        };
                    }),
                    switchMap(result => {
                        return this._store.select(selectThumbParameters)
                            .pipe(
                                map(thumbParameters => {
                                    return {
                                        ...result,
                                        cards: result.cards.map(card => {
                                            card.imageUrl = this.getImageUrl(card.imageName);
                                            this.buildMediaDownloadUrls(card, thumbParameters);

                                            return card;
                                        })
                                    };
                                })
                            );
                    }),
                    map(result => {
                        return ({
                            ...result,
                            mediaSummary: new MatTableDataSource<ProductSummary>(result.cards as ProductSummary[]),
                        });
                    })
                ) :
            of(null);
    }

    buildMediaDownloadUrls(
        card: Partial<ProductSummary>,
        thumbParameters: {
            allowedThumbExtensions: string[];
            allowedThumbSizes: string[]
        }) {

        // Set up the original download URL
        card.downloadUrl = [
            {
                label: 'original',
                url: this.getFilePathToDownload(card.imageName)
            }
        ];

        // Set up thumbnail download URLs if the file extension is allowed
        if (thumbParameters.allowedThumbExtensions.includes(`.${card.fileExtension.toLowerCase()}`)) {
            card.downloadUrl.push(
                ...thumbParameters.allowedThumbSizes.map(size => ({
                    label: size,
                    url: this.getFilePathToDownloadCustom(card.imageName, size)
                }))
            );
        }
    }
}
