import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, filter, map, mergeMap, switchMap} from 'rxjs/operators';
import {ElementRepository} from 'src/lib/data/services/element-repository';
import {Store} from '@ngrx/store';
import {selectDataLanguage} from '../data-languages';
import {selectElementPreviewConfigs, selectMediaPreviewConfigs} from '@store/selector';
import {MediaFacade, ProductsFacade} from 'src/app/data/facades';
import {combineLatest, of} from 'rxjs';
import {selectAllDicoCharacteristics} from '../characteristics';
import {environment} from 'src/environments/environment';
import {NpInstanceService} from '@data/services';
import {EntityName} from 'src/lib/data/model/portal';
import {DicoCarac} from 'src/lib';
import {FavoriteActions} from './favorite.actions';
import {ParamsFilterBuilder} from '@data/builders';
import * as _ from 'lodash';
import {cloneAdvSearchParam} from '@np/utils';
import {ThumbsService} from '@data/services';
import {selectThumbParameters} from '../file';

@Injectable()
export class FavoriteEffect {
    constructor(
        private actions$: Actions,
        private _elementRepository: ElementRepository,
        private _store: Store,
        private _productsFacade: ProductsFacade,
        private _instanceService: NpInstanceService,
        private _mediaFacade: MediaFacade,
        private _thumbsService: ThumbsService) {
    }

    loadElementsFromFavorite$ = createEffect(() => {
            return this.actions$.pipe(
                ofType(FavoriteActions.loadElementsFromFavorite),
                mergeMap(() => {
                    const favorites = this.getFavoritesFromLocalStorage();

                    return this._instanceService.currentEntity.name === EntityName.MediaLibrary ? this._getMediaFavorites(favorites) : this._getProductFavorites(favorites);
                }),
                map(favorites => FavoriteActions.loadElementsFromFavoriteSuccess({favorites: favorites}))
            );
        },
    );

    toggleFavorite$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(FavoriteActions.toggleFavorite),
                map(action => {
                    const favorite = action.favorites;
                    let storedFavorites = this.getFavoritesFromLocalStorage();
                    if (!storedFavorites.some(element => favorite.extID === element)) {
                        storedFavorites.push(favorite.extID);
                    } else {
                        storedFavorites = storedFavorites.filter(item => item !== favorite.extID);
                    }

                    localStorage.setItem(
                        environment.favoriteKey,
                        JSON.stringify(storedFavorites)
                    );

                    return this._store.dispatch(FavoriteActions.updateFavorites({favoriteExtIds: storedFavorites}));
                })
            );
        },
        {dispatch: false}
    );

    toggleAllFavorites$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FavoriteActions.toggleFavorites),
            switchMap(action => {
                const clonedParams = this._cloneSearchParams(action.paramsFilterBuilder);
                return this._fetchProductElementExtIds(clonedParams)
                    .pipe(map(elementExtIdsToAdd => ({elementExtIdsToAdd, action})));
            }),
            map(response => {
                const updatedFavorites = this._updateFavorites(response.action, response.elementExtIdsToAdd);
                this._updateLocalStorage(updatedFavorites);
                this._store.dispatch(FavoriteActions.updateFavorites({favoriteExtIds: updatedFavorites}));
                return FavoriteActions.toggleAllFavoritesSuccess();
            }),
            catchError(err => {
                console.error(err);
                return of(FavoriteActions.toggleAllFavoritesFailure({message: err}));
            })
        );
    });

    // Clone advanced search parameters
    private _cloneSearchParams(paramsFilterBuilder: ParamsFilterBuilder) {
        return cloneAdvSearchParam(paramsFilterBuilder, {fieldsToDisplay: [DicoCarac.PRODUCT_LABEL]});
    }

    // Retrieves product ids
    private _fetchProductElementExtIds(clonedParams: ParamsFilterBuilder) {
        return this._productsFacade.fetchElementExtIds(clonedParams.build());
    }

    // Updates favorites
    private _updateFavorites(
        action: {
            paramsFilterBuilder: ParamsFilterBuilder,
            isToggleAllFavorites: boolean
        },
        elementExtIdsToAdd: string[]
    ) {
        const existingFavorites = this.getFavoritesFromLocalStorage();
        return action.isToggleAllFavorites
            ? _.union(existingFavorites, elementExtIdsToAdd)
            : _.difference(existingFavorites, elementExtIdsToAdd);
    }

    // Updates favorites in the localStorage
    private _updateLocalStorage(updatedFavorites: string[]) {
        localStorage.setItem(environment.favoriteKey, JSON.stringify(updatedFavorites));
    }

    getFavoritesFromLocalStorage(): string[] {
        const storedFavorites = localStorage.getItem(environment.favoriteKey);
        return storedFavorites ? JSON.parse(storedFavorites) : [];
    }

    private _getElementByExtIds = (elementExtIds: string[]) => {
        let previewConfig = selectElementPreviewConfigs;
        if (this._instanceService.currentEntity.name === EntityName.MediaLibrary) {
            previewConfig = selectMediaPreviewConfigs;
        }
        return combineLatest([
            this._store.select(selectDataLanguage),
            this._store.select(previewConfig)
            .pipe(filter(result => !!result.mainVisualCaracExtId)),
            this._store.select(selectAllDicoCharacteristics)
        ]).pipe(
            switchMap(([selectedDataLang, previewParams, dicoCaracs]) => {
                const userDicoCaracs = previewParams?.previewCaracs.map(previewCarac => {
                    return dicoCaracs.find(dicoCarac => dicoCarac?.ExtID === previewCarac);
                });
                const characeristicsListOfLinkType = userDicoCaracs
                    .filter(characteristic => characteristic?.ExtID !== previewParams?.mainVisualCaracExtId && characteristic?.TypeCode.includes('LIEN'))
                    .map(characteristic => characteristic?.ExtID)
                    .map(element => [element, previewParams?.mainVisualCaracExtId]);
                characeristicsListOfLinkType.push([previewParams?.mainVisualCaracExtId]);

                return this._elementRepository.getElements(
                    elementExtIds,
                    characeristicsListOfLinkType,
                    previewParams.previewCaracs,
                    selectedDataLang?.ID
                );
            })
        );
    }

    private _getMediaFavorites(favorites: string[]) {
        return this._store.select(selectThumbParameters).pipe(
            switchMap(thumbParameters =>
                this._getElementByExtIds(favorites).pipe(
                    map(result => {
                        return Array.from(result.values()).map(element => {
                            const card = this._mediaFacade.npElementToCards(element);
                            card.imageUrl = this._mediaFacade.getImageUrl(card.imageName);

                            if (this._instanceService.currentEntity.name === EntityName.MediaLibrary) {
                                this._mediaFacade.buildMediaDownloadUrls(card, thumbParameters);
                            }

                            return card;
                        });
                    })
                )
            )
        );
    }

    private _getProductFavorites = (favorites: string[]) => {
        return this._getElementByExtIds(favorites).pipe(
            map(result => {
                return Array.from(result.values()).map(element => {
                    return ({
                        urlWithoutToken: this._productsFacade.getImageUrlWithoutToken(element),
                        imageUrl: this._productsFacade.getImageUrl(element),
                        label: element.Label,
                        element: element,
                    });
                });
            })
        );
    }
}
