import {Component, Input} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {ElementSummary, MediaSummary, ProductSummary} from '@app/models';
import {TABLE_HEADER_LABELS} from '@data/constants';
import {SelectionModel} from '@angular/cdk/collections';
import {SpUserInfoService} from '@data/services';
import {FileReaderService} from '@nextpage/np-sdk-data';
import {Store} from '@ngrx/store';
import {MatDialog} from '@angular/material/dialog';
import {DialogMediaSheetComponent} from 'src/app/graphics/components/dialog-media-sheet/dialog-media-sheet.component';
import { selectFavoritesExtIds } from 'src/app/store/selector';
import {FavoriteActions} from '@store/favorites';

@Component({
    selector: 'app-media-table',
    templateUrl: './media-table.component.html',
    styleUrls: ['./media-table.component.scss']
})
export class MediaTableComponent {
    @Input() columnsToDisplay: string[] = [];
    @Input() dataSource: MatTableDataSource<ProductSummary>;
    @Input() headerLabels = TABLE_HEADER_LABELS;

    public selection = new SelectionModel<MediaSummary>(true, []);
    public selectedItem: ProductSummary[] = [];
    public favoritesState$ = this.store.select(selectFavoritesExtIds);

    constructor(
        private _userInfoService: SpUserInfoService,
        private _fileReaderImage: FileReaderService,
        private store: Store,
        public dialog: MatDialog,
        private _fileReaderService: FileReaderService,
    ) {
    }

    getSelectedValue(item) {
        this.selectedItem.push(item);
    }

    toggleFavorite(elementSummary: ElementSummary, $event: Event) {
        $event.stopPropagation();
        elementSummary.isFavoriteElement = !elementSummary.isFavoriteElement;
        this.store.dispatch(
            FavoriteActions.toggleFavorite({
                favorites: {
                    extID: elementSummary.element.ExtID
                },
            })
        );
    }

    showMoreInfos(element) {
        this.dialog.open(DialogMediaSheetComponent, {
            hasBackdrop: true,
            disableClose: false,
            height: '95%',
            width: '80%',
            data: element
        });
    }
}
