import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ProductSummary} from 'src/app/data/models';
import { NpInstanceService } from "@data/services";
import {DialogService} from 'src/app/graphics/services/dialog.service';

@Component({
  selector: 'app-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.scss'],
})
export class ProductItemComponent implements OnChanges {

  @Input() product: ProductSummary;
  @Input() descriptionFieldExtId: string;

  public imageByDefault$ =  this._instanceService.getImageByDefault();
  public productDescriptionValue: string;

  constructor(
    public dialog: MatDialog,
    private readonly _dialogService: DialogService,
    private _instanceService: NpInstanceService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.descriptionFieldExtId && changes.product) {
      this.productDescriptionValue = this.product.element.getValue(this.descriptionFieldExtId)?.Value;
    }
  }

  public viewProductDetails(element: ProductSummary, $event: Event): void {
    $event.stopPropagation();
    this._dialogService.openProductDialog(element);
  }
}
