import {createAction, props} from '@ngrx/store';
import {AdvSearchArgs, NPElement, NpMedia} from '@nextpage/np-sdk-data';
import {MediaSearchResult} from '../../data/models/advanced-search-result.model';
import {UploadImage} from '../../../lib/graphics/np-media-library/np-media-library.component';
import {MediaSummary} from '@app/models';

export const SearchMedia = createAction('[Media Library] Search Media',
    props<{ advancedSearch: AdvSearchArgs, multiOperators: boolean }>());
export const SearchMediaSuccess = createAction('[Media Library] Search Media Success', props<{
    searchResult: MediaSearchResult
}>());
export const SearchMediaFailure = createAction('[Media Library] Search Media Failure', props<{
    message: string
}>());

export const UploadFiles = createAction(
    '[Upload] Upload Files',
    props<{ uploadImages: UploadImage[] }>()
);

export const UploadFileSuccess = createAction(
    '[Upload] Upload File Success',
    props<{ file: NPElement }>()
);

export const UploadFileFailure = createAction(
    '[Upload] Upload File Failure',
    props<{ error: string }>()
);

export const createMediaByExternalUrl = createAction(
    '[Create] Create Media By External Url',
    props<{ url: string }>()
);

export const createMediaByExternalUrlSuccess = createAction(
    '[Create] Create Media By External Url Success',
    props<{ url: string }>()
);

export const createMediaByExternalUrlFailure = createAction(
    '[Create] Create Media By External Url Failure',
    props<{ error: string }>()
);

export const UpdateMedia = createAction(
    '[Update] Update File',
    props<{ uploadMedia: File, media: MediaSummary }>()
);

export const UpdateMediaSuccess = createAction(
    '[Update] Update Media Success',
    props<{ uploadMedia: NPElement }>()
);

export const UpdateMediaFailure = createAction(
    '[Update] Update Media Failure',
    props<{ error: string }>()
);

export const StartSaving = createAction('[Media] Start Saving');

export const StopSaving = createAction('[Media] Stop Saving');


