import {Component, inject} from '@angular/core';
import {SpUserInfoService} from 'src/app/data/services';
import {tap} from 'rxjs/operators';
import {ExportHelper} from '../../../data/helpers/export.helper';
import {DialogService} from '../../services/dialog.service';
import {Store} from '@ngrx/store';
import {ExecuteElementsExport} from '@store/exports';
import {SearchExtensionComponent} from '../../utils/search-extension.component';


@Component({
    selector: 'app-export-base',
    template: '',
    standalone: true
})
export class ExportBaseComponent extends SearchExtensionComponent {
    protected readonly _userInfoService = inject(SpUserInfoService);
    protected readonly _dialogService = inject(DialogService);
    protected readonly _store = inject(Store);

    constructor() {
        super();
    }

    protected exportElements(elementIds: number[]) {
        this._dialogService.openExportDialog()
            .pipe(
                tap(exportConfig => {
                    if (exportConfig) {
                        this._store.dispatch(ExecuteElementsExport({
                            payload: {
                                elementIds: elementIds,
                                exportConfig: exportConfig,
                                fileName: ExportHelper.generateExportName()
                            }
                        }));
                    } else {
                        this._dialogService.closeAll();
                    }
                })
            )
            .subscribe();
    }

    protected exportElements$(elementIds: number[]) {
        return this._dialogService.openExportDialog()
            .pipe(
                tap(exportConfig => {
                    if (exportConfig) {
                        this._store.dispatch(ExecuteElementsExport({
                            payload: {
                                elementIds: elementIds,
                                exportConfig: exportConfig,
                                fileName: ExportHelper.generateExportName()
                            }
                        }));
                    } else {
                        this._dialogService.closeAll();
                    }
                })
            );
    }

}
