<mat-dialog-content style="height: 100%">
    <div class="content-information">
        <div class="title-information">
            <h4 title="{{data.label}}">{{ data.label }}</h4>
            <div>
                <img *ngIf="!isEditing" style="cursor: pointer" (click)="print()" src="./assets/icons/print.svg"
                     class="fontawesome-icon-custom" alt="print">
                <img mat-dialog-close="true" src="./assets/icons/x-mark.svg" class="fontawesome-icon-custom"
                     alt="close">
            </div>
        </div>
        <app-loader-spinner *ngIf="loading"></app-loader-spinner>
        <mat-card class="mat-card-container">
            <mat-card-content>
                <div class="edit-media">
                    <button mat-raised-button (click)="edit()">
                        <fa-icon [icon]="['fal', 'pen']"></fa-icon>
                        {{ "medias.edit-media" | translate }}
                    </button>
                </div>
                <div class="content-information-details">
                    <div class="edit-image-container">
                        <img class="imageUrlStyle" [src]="data.imageUrl ? data.imageUrl : imageByDefault$ | async " alt="Aperçu du média" />
                        <div class="upload-new-media" *ngIf="isEditing">
                            <input
                                #inputFile
                                style="display: none"
                                type="file"
                                multiple="multiple"
                                (change)="onFileSelected($event)"/>
                            <button mat-raised-button  (click)="inputFile.click()">
                                <i class="pr-1 pi pi-download"></i>
                                {{ 'medias.upload-new-media' | translate }}
                            </button>
                        </div>
                    </div>
                    <div class="information-details">
                        <div class="caracs-container">
                            <div class="pField">
                                <strong>{{ 'medias.name' | translate }} :</strong>
                                <span *ngIf="data.label">{{ data.label }}</span>
                            </div>
                            <div class="pField" *ngFor="let field of fields">
                                <strong>{{field.label}} :</strong>
                                <span *ngIf="field.value && !field.pdf">{{ field.value }}</span>
                                <a *ngIf="!!field.pdf" [href]="field.pdf">{{ field.value }}</a>
                                {{field.unit}}
                            </div>
                        </div>
                        <div *ngIf="!isEditing">
                            <strong>{{ 'medias.download' | translate }} :</strong>
                            <div class="downloadUrl" *ngFor="let file of data.downloadUrl">
                                <span>
                                    <mat-chip> {{ (file.label === 'original' ? 'medias.file' : 'medias.thumbnail') | translate}}</mat-chip>  {{ file.label }}
                                </span>
                                <span>
                                    <a (click)="downLoad(file.url)" class="download-item" [attr.title]="'medias.download-tooltip' | translate">
                                        <img ngSrc="./assets/icons/download.svg"
                                             class="fontawesome-icon-custom" alt="apps"
                                             height="16" width="16">
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-dialog-actions align="end" *ngIf="isEditing">
            <button mat-button mat-dialog-close (click)="cancelEdit()">{{ 'common.cancel' | translate }}</button>
            <button
                    mat-raised-button
                    cdkFocusInitial
                    color="primary"
                    (click)="save()"
                    [disabled]="isSaving$ | async"
            >
                <div class="is-saving">
                    <mat-spinner *ngIf="isSaving$ | async" diameter="20" strokeWidth="3"></mat-spinner>
                    <span>{{ 'common.save' | translate }}</span>
                </div>
            </button>
        </mat-dialog-actions>
    </div>
</mat-dialog-content>
