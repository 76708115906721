export class ExportDto {
    Config: {
        ExportType: string,
        OutFileName: string,
        OneFileByLangue: boolean,
        Produits: unknown[],
        DicoCaracteristiques: unknown[],
        CaracLie: [],
        User: {
            ID: number,
            Email: string
        },
        SendTo: string;
        Langues: number[],
        Format: string,
        ContexteID: number,
        LangID: number,
        JobImportExportConfigExtID: string,
        AuthorizeDownload: {
            Enable: boolean,
            keepDays: number
        },
        Options: {
            Emails: unknown[],
            Description: string,
            OutPut: {
                ExportMedia: boolean,
                ExportMediaThumb: boolean,
                ExportMediaFormats: string[],
                SendStdFile: boolean,
                SimplifiedExport: boolean
            },
            DossierDicoCaracIDToExclude: unknown[],
            WorkflowStatusList: unknown[]
        }
    };
    Name: string;
    SchedulerStatus: number;
    IsForSSIS: boolean;
}

export const EXPORT_OBJECT = {
    Config: {
        ExportType: 'Elements',
        OutFileName: '',
        OneFileByLangue: false,
        Produits: [],
        DicoCaracteristiques: [],
        CaracLie: [],
        User: {
            ID: 0,
            Email: ''
        },
        SendTo: '',
        Langues: [1],
        Format: 'TXT',
        ContexteID: 1,
        LangID: 1,
        JobImportExportConfigExtID: '',
        AuthorizeDownload: {
            Enable: true,
            keepDays: 1
        },
        Options: {
            Emails: [],
            Description: '',
            OutPut: {
                ExportMedia: false,
                ExportMediaThumb: false,
                ExportMediaFormats: [],
                SendStdFile: true,
                SimplifiedExport: false
            },
            DossierDicoCaracIDToExclude: [],
            WorkflowStatusList: []
        }
    },
    Name: 'Export Product',
    SchedulerStatus: 1,
    IsForSSIS: true,
};
